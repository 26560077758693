import CategoryCard from "../Components/Cards/CategoryCard";
import data from "../data/shop.json";
import styled from "styled-components";
import DemandBtn from "../Components/Shop/DemandButton";

export default function ShopPage() {
  const categoryData = data.category;

  const categoryCards = categoryData.map((category) => (
    <CategoryCard
      title={category.title}
      image={category.image}
      path={category.path + "/" + category.title}
      key={category.title}
    />
  ));
  return (
    <PageSec>
      <TitlePart>
        <h1>به فروشگاه خوش آمدید.</h1>
        <DemandBtn />
      </TitlePart>
      <CategoryPart>{categoryCards}</CategoryPart>
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c7ddcc;
  height: 100vh;
`;

const TitlePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 60px;
  position: relative;
  width: 80%;

  & > h1 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
  }

  & > h2 {
    font-size: 24px;
    text-align: center;
  }
`;

const CategoryPart = styled.div`
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
`;
