import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button,
  Box,
  Typography,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useUser } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import { BaseBackURL } from "../Constant/apis";
import axios from "axios";
import SelectDate from "../Components/selectDate";
import { Formik, useFormik } from "formik";
import { AddressSchema, confirmSchema } from "../Components/schema";
import { LoadingButton } from "@mui/lab";
import CloseButton from "../Components/closeButton";
import data from "../data/state.json";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import { useRef } from "react";

const columns = [
  {
    field: "sunday",
    headerName: "شنبه",
    renderCell: () => <SelectDate />,
    flex: 1,
  },
  {
    field: "saturday",
    headerName: "یکشنبه",
    renderCell: () => <SelectDate />,
    flex: 1,
  },
  {
    field: "monday",
    headerName: "دوشنبه",
    renderCell: () => <SelectDate />,
    flex: 1,
  },
  {
    field: "tuseday",
    headerName: "سه شنبه",
    renderCell: () => <SelectDate />,
    flex: 1,
  },
  {
    field: "wendesday",
    headerName: " چهارشنبه",
    renderCell: () => <SelectDate />,
    flex: 1,
  },
];

const rows = [{ id: 1, sunday: "hamid" }];

export default function InitialInvoice() {
  const { state, dispatch } = useUser();
  const [addModal, setAddModal] = useState(false);
  const [address, setAddress] = useState(state.address);
  const [transfereeAddress, setTransfereeAddress] = useState();
  const [post, setPost] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [delivery, setDelivery] = useState("self");

  const onSubmit = async (values, actions) => {
    // const data = new FormData();
    // data.append("name", values.name);
    // data.append("phonenumber", values.phonenumber);
    // data.append("address", values.address);
    // data.append("time", values.time);

    console.log("result", values);
    navigate("/pay");
    actions.resetForm();
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: state.firstName + state.lastName,
      phoneNumber: state.mobileNumber,
      address: "",
      // time: "",
    },
    validationSchema: confirmSchema,
    onSubmit,
  });

  const getAddress = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}addresses`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        dispatch({ type: "SET_ADDRESS", payload: res.data });
        setAddress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleData = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}customer/detail`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        console.log("res:", res);
        dispatch({ type: "SET_FNAME", payload: res.data.firstname });
        dispatch({ type: "SET_LNAME", payload: res.data.lastname });
        dispatch({ type: "SET_AGE", payload: res.data.age });
        dispatch({ type: "SET_MOBILEN", payload: res.data.mobilenumber });
        dispatch({ type: "SET_PHONEN", payload: res.data.phonenumber });
        dispatch({ type: "SET_SEX", payload: res.data.sex });
        dispatch({ type: "SET_MAIL", payload: res.data.email });
        dispatch({ type: "SET_PERSONALCODE", payload: res.data.personalcode });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAddress();
    handleData();
  }, []);

  useEffect(() => {
    checkLogistic();
  }, [values.address]);

  const selectAddress = state.address.map((item, index) => {
    return (
      <MenuItem
        key={index}
        value={
          item.province +
          "-" +
          item.city +
          "-" +
          item.description +
          "-" +
          item.number +
          "-" +
          item.unit
        }
      >
        {item.province +
          "-" +
          item.city +
          "-" +
          item.description +
          "-" +
          item.number +
          "-" +
          item.unit}
      </MenuItem>
    );
  });

  console.log("values", values);

  const checkLogistic = () => {
    let city;
    if (values.address && values.address !== "") {
      city = values.address.split("-")[0];
    } else {
      city = "";
    }

    if (city === "تهران" || city === "البرز") {
      setPost("peyk");
    } else {
      setPost("post");
    }
  };

  const handleAdd = () => {
    setAddModal(true);
  };

  const addressInitialValues = {
    province: "",
    city: "",
    description: "",
    number: "",
    unit: "",
    postcode: "",
  };

  const formRef = useRef(null);

  return (
    <PageSec>
      <Grid container>
        <Grid item xs={9}>
          <Card sx={{ margin: "20px" }}>
            <CardHeader title="اطلاعات زیر را کامل کنید" />
            <CardContent>
              <form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      // label="نام"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      disabled
                      value={state.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      // label="نام خانوادگی"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      disabled
                      value={state.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DeliveryBox>
                      <DeliveryForm>
                        <h3>تحویل گیرنده:</h3>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="self"
                          name="radio-buttons-group"
                          onChange={(e) => {
                            setDelivery(e.target.value);
                            if (e.target.value === "self") {
                              setFieldValue(
                                "name",
                                state.firstName + " " + state.lastName
                              );
                              setFieldValue("phoneNumber", state.mobileNumber);
                            } else {
                              setFieldValue("name", "");
                              setFieldValue("phoneNumber", "");
                            }
                          }}
                        >
                          <FormControlLabel
                            value="self"
                            control={<Radio />}
                            label="خودم"
                          />
                          <FormControlLabel
                            value="elth"
                            control={<Radio />}
                            label="دیگری"
                          />
                        </RadioGroup>
                      </DeliveryForm>

                      {(delivery === "elth" || errors.phoneNumber) && (
                        <Grid container spacing={6}>
                          <Grid item xs={6}>
                            <CustomTextField
                              id="name"
                              label="تحویل گیرنده"
                              variant="standard"
                              dir="rtl"
                              fullWidth
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                              <ErrorText>{errors.name}</ErrorText>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <CustomTextField
                              id="phoneNumber"
                              label=" شماره تماس"
                              variant="standard"
                              dir="rtl"
                              fullWidth
                              value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <ErrorText>{errors.phoneNumber}</ErrorText>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </DeliveryBox>
                  </Grid>

                  <Grid item xs={12}>
                    {state.address.length === 0 ? (
                      <>
                        {/* <CustomTextField
                      id="address"
                      label="  آدرس خود را وارد کنید"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> */}
                        <Button
                          variant="contained"
                          onClick={handleAdd}
                          startIcon={
                            <AddIcon
                              sx={{ marginLeft: "7px", marginTop: "-4px" }}
                            />
                          }
                        >
                          افزودن آدرس
                        </Button>
                        {errors.address && touched.address && (
                          <ErrorText>{errors.address}</ErrorText>
                        )}
                      </>
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          آدرس
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="address"
                          name="address"
                          label="آدرس"
                          value={values.address}
                          onChange={handleChange}
                        >
                          {selectAddress}
                        </Select>

                        {errors.address && touched.address && (
                          <ErrorText>{errors.address}</ErrorText>
                        )}
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      sx={{ textAlign: "start", marginBottom: "10px" }}
                    >
                      نحوه ارسال سفارش
                    </Typography>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Button
                        variant="contained"
                        disabled={post !== "peyk" ? true : false}
                      >
                        پیک
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        disabled={post !== "post" ? true : false}
                      >
                        پست
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    {post === "peyk" ? (
                      <Box>
                        <Typography sx={{ textAlign: "start" }}>
                          ارسال توسط پیک سنت شاپ
                        </Typography>
                        <Card sx={{ backgroundColor: "#bec2cb" }}>
                          <Typography
                            sx={{
                              padding: "1%",
                              borderBottom: "1px solid black",
                            }}
                          >
                            زمانبندی تحویل کالا
                          </Typography>
                          <CardContent sx={{ width: "100%", height: "40vh" }}>
                            <TableRoot
                              columns={columns}
                              rows={rows}
                              rowHeight={200}
                            />
                          </CardContent>
                        </Card>
                      </Box>
                    ) : (
                      <Card sx={{ backgroundColor: "#bec2cb", padding: "2%" }}>
                        <Typography
                          sx={{ textAlign: "start", marginBottom: "10px" }}
                        >
                          ارسال توسط پست
                        </Typography>
                        <Typography
                          sx={{ textAlign: "start", marginBottom: "10px" }}
                        >
                          کاربر گرامی
                        </Typography>
                        <Typography sx={{ textAlign: "start" }}>
                          سفارش شما در اسرع وقت از سامانه پست برایتان ارسال
                          میگردد.کد مرسوله پستی از طریق پیامک برای شما ارسال
                          میگردد.
                        </Typography>
                      </Card>
                    )}
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Button
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      ثبت
                    </Button>
                  </Grid> */}
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ margin: "20px" }}>
            <CardHeader title="جزئیات فاکتور" />
            <CardContent>
              <TotoalContainer>
                <Typography>تعداد اقلام:</Typography>
                <Typography>{state.cart.length}</Typography>
              </TotoalContainer>
              <TotoalContainer>
                <Typography>جمع کل قبل از تخفیف:</Typography>
                <Typography>
                  {" "}
                  <CustomCurrency
                    value={state.currentPrice}
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                  />{" "}
                </Typography>
              </TotoalContainer>
              <TotoalContainer>
                <Typography>سود شما از این خرید:</Typography>
                <Typography>
                  <CustomCurrency
                    value={state.systemDiscount}
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                  />{" "}
                </Typography>
              </TotoalContainer>
              <TotoalContainer>
                <Typography>جمع کل بعد از تخفیف:</Typography>
                <Typography>
                  <CustomCurrency
                    value={state.currentPrice - state.systemDiscount}
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                  />{" "}
                </Typography>
              </TotoalContainer>
              <TotoalContainer>
                <Typography>هزینه ارسال:</Typography>
                <Typography>
                  <CustomCurrency
                    value={0}
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                  />{" "}
                </Typography>
              </TotoalContainer>
              <TotoalContainer className="FinalPrice">
                <Typography>مبلغ قابل پرداخت:</Typography>
                <Typography>
                  <CustomCurrency
                    value={state.currentPrice - state.systemDiscount}
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                  />{" "}
                </Typography>
              </TotoalContainer>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  marginTop: "30px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  بازگشت به فروشگاه
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  style={{ marginTop: "20px" }}
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  ادامه فرایند خرید
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <AddresseModal>
          <Card dir="rtl" sx={{ width: "100%" }}>
            <CloseButton
              onClose={() => {
                setAddModal(false);
              }}
            />
            <CardHeader title="آدرس جدید" />
            <CardContent>
              <Formik
                validationSchema={AddressSchema}
                initialValues={addressInitialValues}
                initialErrors={addressInitialValues}
                onSubmit={(values, { resetForm }) => {
                  // resetForm();
                  // console.log("hello");
                  // const data = new FormData();
                  // data.append("province", values.province);
                  // data.append("city", values.city);
                  // data.append("description", values.description);
                  // data.append("number", values.number);
                  // data.append("unit", values.unit);
                  // data.append("postcode", values.postcode);
                  // setLoading(true);
                  // let config = {
                  //   method: "post",
                  //   url: `${BaseBackURL}addresses/`,
                  //   headers: { Authorization: "Bearer " + state.token },
                  //   data: data,
                  // };
                  // axios(config)
                  //   .then((res) => {
                  //     console.log("change:", res);
                  //     toast.success("اطلاعات شما با موفقیت ثبت شد !", {
                  //       position: toast.POSITION.TOP_RIGHT,
                  //     });
                  //     setLoading(false);
                  //     getAddress();
                  //   })
                  //   .catch((err) => {
                  //     console.log(err);
                  //     toast.error("ارتباط با سرور برقرا نشد!", {
                  //       position: toast.POSITION.TOP_RIGHT,
                  //     });
                  //     setLoading(false);
                  //   });
                  // setAddModal(false);
                }}
              >
                {({
                  setFieldValue,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={4}>
                      {/* {SelectingState} */}
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          id="province"
                          label="استان"
                          variant="standard"
                          dir="rtl"
                          fullWidth
                          required
                          value={values.province}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.province && touched.province && (
                          <ErrorText>{errors.province}</ErrorText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          id="city"
                          label="شهر"
                          variant="standard"
                          dir="rtl"
                          fullWidth
                          required
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city && (
                          <ErrorText>{errors.city}</ErrorText>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          id="postcode"
                          label="کدپستی"
                          variant="standard"
                          dir="rtl"
                          fullWidth
                          required
                          value={values.postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.postcode && touched.postcode && (
                          <ErrorText>{errors.postcode}</ErrorText>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          id="description"
                          label="آدرس"
                          variant="standard"
                          dir="rtl"
                          fullWidth
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.description && touched.description && (
                          <ErrorText>{errors.description}</ErrorText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          id="number"
                          label="پلاک"
                          variant="standard"
                          dir="rtl"
                          fullWidth
                          type="tel"
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.number && touched.number && (
                          <ErrorText>{errors.number}</ErrorText>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomTextField
                          id="unit"
                          label="واحد"
                          variant="standard"
                          dir="rtl"
                          fullWidth
                          type="tel"
                          value={values.unit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.unit && touched.unit && (
                          <ErrorText>{errors.unit}</ErrorText>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          variant="contained"
                          disabled={isSubmitting}
                          onClick={() => {
                            handleSubmit();
                            console.log("hello");
                            const data = new FormData();
                            data.append("province", values.province);
                            data.append("city", values.city);
                            data.append("description", values.description);
                            data.append("number", values.number);
                            data.append("unit", values.unit);
                            data.append("postcode", values.postcode);
                            setLoading(true);
                            let config = {
                              method: "post",
                              url: `${BaseBackURL}addresses/`,
                              headers: {
                                Authorization: "Bearer " + state.token,
                              },
                              data: data,
                            };
                            axios(config)
                              .then((res) => {
                                console.log("change:", res);
                                toast.success(
                                  "اطلاعات شما با موفقیت ثبت شد !",
                                  {
                                    position: toast.POSITION.TOP_RIGHT,
                                  }
                                );
                                setLoading(false);
                                getAddress();
                              })
                              .catch((err) => {
                                console.log(err);
                                toast.error("ارتباط با سرور برقرا نشد!", {
                                  position: toast.POSITION.TOP_RIGHT,
                                });
                                setLoading(false);
                              });
                            setAddModal(false);
                          }}
                        >
                          ثبت
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </AddresseModal>
      </Dialog>
    </PageSec>
  );
}

const PageSec = styled.section`
  background-color: #c7ddcc;
`;

const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;

const PostContainer = styled.div`
  width: 80%;
  border-radius: 8px;
  background-color: #bec2cb;
`;

const TableRoot = styled(DataGrid)`
  .MuiDataGrid-footerContainer {
    display: none;
  }
`;

const ErrorText = styled.p`
  color: #fc8181;
  font-size: 0.75rem;
  width: 100%;
  text-align: right;
  margin: 0;
  margin-right: 2%;
  margin-top: 2%;
`;

const AddresseModal = styled.div`
  padding: 20px;
`;

const DeliveryBox = styled.div`
  h3 {
    font-size: 20px;
  }
`;

const DeliveryForm = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: row;
  }
`;

const TotoalContainer = styled.div`
  display: felx;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin: 10px auto;

  &.FinalPrice {
    p {
      font-weight: 700;
    }
  }
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;
