import * as yup from "yup";

const passwordRules = (/[1-4]/g);
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const basicSchema = yup.object().shape({
  username: yup.string().required("لطفا نام کاربری خود را وارد کنید"),
  password: yup
    .string()
    .min(5, "لطفا حداقل ۵کاراکتر وارد کنید")
    // .matches(passwordRules, { message: "لطفا یک رمز قوی انتخاب کنید" })
    .required("لطفا رمز عبور خود را وارد کنید"),
});

export const signInSchema = yup.object().shape({
  username: yup.string().required("لطفا نام کاربری خود را وارد کنید"),
  firstName: yup.string().required("لطفا نام خود را وادر کنید"),
  lastName: yup.string().required("لطفا نام خانوادگی خود را وارد کنید"),

  password: yup
    .string()
    .min(10, "لطفا حداقل ۱۰ کاراکتر وارد کنید")
    .matches(passwordRules, { message: "لطفا از اعداد و حروف استفاده کنید" })
    .required("لطفا رمز عبور خود را وارد کنید"),

    confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "تکرار پسورد اشتباه است")
    .required("لطفا رمز عبور خود را تکرار کنید"),
});


export const AddressSchema =yup.object().shape({
  province: yup.string().required("لطفا استان را مشخص کنید"),
  city: yup.string().required("لطفا شهر خود را مشخص کنید"),
  description: yup.string().required("لطفا آدرس خود را وارد کنید"),
  number:yup.number("لطفا کیبود خود را به زبان انگلیسی تغییر دهید").required("لطفا پلاک خود را وارد کنید"),
  unit:yup.number().positive().integer().required("لطفا واحد را مشخص کنید"),
  postcode:yup.number( {message:"لطفا کیبود خود را به زبان انگلیسی تغییر دهید"}).min("10" ,"لطفا حداقل ۱۰ کاراکتر وارد کنید").required("لطفا کدپستی را وارد کنید"),
});


export const transactionSchema = yup.object().shape({
  price: yup.string().required("لطفا مبلغ واریزی را وارد کنید"),
  time: yup.string().required("لطفا زمان واریز را وارد کنید"),
  date:yup.date().required('لطفا تاریخ واریز را مشخص کنید'),
  code : yup.number().min(6 ,"لطفا حداقل ۶ کاراکتر وارد کنید").required('لطفا کد تراکنش را وارد کنید'),
});

export const commentSchema = yup.object().shape({
  type: yup.string().min(5 ,'لطفا حداقل ۵ کاراکتر وارد کنید').required("لطفا موضوع مورد نظر را مشخص کنید"),
  description: yup.string().min(10 ,'لطفا حداقل ۱۰ کاراکتر وارد کنید').required("لطفا شرح موضوع را وارد کنید"),

});

export const confirmSchema = yup.object().shape({
  name: yup.string().min(5 ,'لطفا حداقل ۵ کاراکتر وارد کنید').required("لطفا نام تحویل گیرنده را وارد کنید"),
  phoneNumber: yup.number("لطفا کیبود خود را به زبان انگلیسی تغییر دهید").required("لطفا شماره تماس تحویل گیرنده  را وارد کنید"),
  address: yup.string().min(5 ,'لطفا حداقل ۵ کاراکتر وارد کنید').required("لطفا  آدرس را وارد کنید"),
});