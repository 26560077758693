import React ,{useEffect,useState}from "react";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useUser } from "../../context/userContext";
import { BaseBackURL } from "../../Constant/apis";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,

} from "@mui/material";
import CloseButton from "../../Components/closeButton";
import Invoice from "../../Components/ invoice";

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: ltr;
  }
`;




  

const Purchases = () => {
  const { state, dispatch } = useUser();
  const [rows, setRows] = useState(state.orders);
  const [addModal,setAddModal]=useState(false)

  const columns = [
    { field: "id", headerName: "ردیف", width: 70 ,renderCell:(row)=>{return rows.indexOf(row.row) +1 },flex: 1},
    { field: "date", headerName: " تاریخ", width: 130,flex: 1 },
    { field: "number", headerName: "شماره فاکتور", width: 130 ,flex: 1},
    {
      field: "product",
      headerName: " کالا",
      width: 140,
      flex: 1
    },
    {
      field:"price",
      headerName:"ارزش کل فاکتور",
      width:200,
      flex: 1
    },
  
    {
      field: "producter",
      headerName: "خریدار",
      width: 130,
      flex: 1
    },
    {
      field: "row",
      headerName: "مشاهده فاکتور",
      width: 150,
      renderCell: (row) => {
        const selectRow = rows.find (x=>x.id === row.row.id);
        return (

          <>
            <Button variant="contained" onClick={()=>{setAddModal(true)}}>مشاهده</Button>
            <Dialog
              open={addModal}
              onClose={() => {
                setAddModal(false);
              }}
            > 
              <Card sx={{padding:"20px",width:"40vw"}}>
                <CloseButton onClose={()=>{setAddModal(false)}}/>
                
                <CardContent>
                  <Invoice rows={[selectRow]}/> 
                </CardContent>
              </Card>

            </Dialog>
          </>
        );
      },
    },
  ];

  useEffect(()=>{
    getPurchases();
  },[])


  const getPurchases = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}purchases`,
      headers: { "Authorization": "Bearer "+state.token },
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_PURCHASES", payload: res.data });
        setRows(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <div style={{ height: "90vh", width: "80%", background: "#fff" }}>
      <TableRoot
        rows={rows}
        columns={columns}
        ppostCodeSize={5}
        rowsPerPpostCodeOptions={[5]}
        checkboxSelection
      />
    </div>
  );
};

export default Purchases;
