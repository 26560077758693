import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import data from "../../data/menu.json";
import { useUser } from "../../context/userContext";
import LogoImage from "../../Assets/main/full-logo.webp";
import styled from "styled-components";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import useWidth from "../../hook/useWidth";

// import { Link } from "@mui/material";

export default function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const pages = data.menu;
  const width = useWidth();

  const { state, dispatch } = useUser();
  // console.log("user", state);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = () => {
    dispatch({ type: "CLEAR_DATA", payload: null });
    navigate("/");
  };

  return (
    <AppBar
      sx={{
        position: "static",
        backgroundColor: "rgb(255,255,255,0.5)",
        top: "0px",
        padding: "10px",
        color: "black",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <LogoLink href="https://sonatshop.com/">
            <Logo src={LogoImage} alt="سنت شاپ" />
          </LogoLink>
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            سنت شاپ
          </Typography> */}

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((item) => (
                <MenuItem
                  key={item.name}
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  <Typography xs={{ textAlign: "center", color: "red" }}>
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <CustomButton
                key={page.name}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                <a href={page.href}> {page.name}</a>
              </CustomButton>
            ))}
          </Box>

          <CustomBox xs={{ backgroundColor: "black" }}>
            <Button
              onClick={() => navigate("/cart")}
            >
              <Badge color="info" badgeContent={state.cart.length}>
                <ShoppingCartIcon />{" "}
              </Badge>
              {width>480 ? <p>سبد خرید</p> : ""}
            </Button>
          </CustomBox>

          {width > 480 && (
            <CustomBox xs={{ backgroundColor: "#FFB8B8" }}>
              <Button
                onClick={() => navigate("/dashboard")}
                endIcon={<DashboardIcon />}
              >
                <p>داشبورد</p>
              </Button>
            </CustomBox>
          )}

          <CustomBox>
            <Button 
            onClick={() => navigate("/")} 
            endIcon={width>480? <LocalMallIcon />:""}>
              <p>فروشگاه</p>
            </Button>
          </CustomBox>

          <Box sx={{ flexGrow: 0 }}>
            {state.token ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={state.lastName}
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      textAlign="center"
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      داشبورد
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">کیف پول</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleSignOut}>
                    <Typography textAlign="center">خروج</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Typography
                  textAlign="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  ورود
                </Typography>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const LogoLink = styled.a`
  display: flex;
`;

const Logo = styled.img`
  width: 13vw;
  height: auto;
  margin-left: 20px;
`;

const CustomButton = styled(Button)`
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: #61ce70;
    }
  }
`;

const CustomBox = styled(Box)`
  font-size: 15px;
  font-weight: bold;
  background-color: #9d0303;
  border-radius: 20px 20px 20px 20px;
  padding: 2px 5px;
  margin-inline: 10px;
  &:hover {
    background-color: black;
  }
  button {
    color: white;
    p {
      margin: 0;
      margin-inline: 10px;
    }
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 2px;
    margin-inline: 3px;
    button {
      color: white;
      p {
        font-size: 13px;
        margin: 0;
        margin-inline: 3px;
      }
    }
  }
`;
