import DashboardIcon from "../../Assets/dashboard/DashboardIcon.svg";
import DashboardIconActive from "../../Assets/dashboard/DashboardIconActive.svg";
import TransactionsIcon from "../../Assets/dashboard/TransactionsIcon.svg";
import TransactionsIconActive from "../../Assets/dashboard/TransactionsIconActive.svg";
import SecureIcon from "../../Assets/dashboard/SecureIcon.svg";
import SecureIconActive from "../../Assets/dashboard/SecureIconActive.svg";
import SettingsIcon from "../../Assets/dashboard/SettingsIcon.svg";
import SettingsIconActive from "../../Assets/dashboard/SettingsIconActive.svg";
import AddressIcon from "../../Assets/dashboard/svg.svg";
import AddressIconsActive from "../../Assets/dashboard/address-icon.svg";
import SignOutIcon from "../../Assets/dashboard/SignOutIcon.svg";
import AccountsIcon from "../../Assets/dashboard/AccountsIcon.svg";
import AccountsIconActive from "../../Assets/dashboard/AccountsIconActive.svg";
import suggestion from "../../Assets/dashboard/suggestion.svg";
import suggestionActive from "../../Assets/dashboard/suggestion-color.png";
import tiket from "../../Assets/dashboard/tiket.svg";
import tiketActive from "../../Assets/dashboard/tiketActive.png";
import discount from "../../Assets/dashboard/discount.svg";
import discountActive from "../../Assets/dashboard/discountActive.png";
import order from "../../Assets/dashboard/order.svg";
import orderActive from "../../Assets/dashboard/orderActive.png";
import Dashboard from "./Dashboard";
import Account from "./Account";
import Addresses from "./Addresses";
import Orders from "./Orders";
import Settings from "./Settings";
import Financial from "./Financial";
import Purchases from "./Purchases";
import Discounts from "./Discounts";
import Comments from "./Comments";
import Demands from "./Demands";

const routes = [
  {
    label: "داشبورد",
    path: "/dashboard/",
    subPath: "/",
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
    component: Dashboard,
  },
  {
    label: "پروفایل",
    path: "/dashboard/accounts",
    subPath: "/accounts",
    icon: AccountsIcon,
    activeIcon: AccountsIconActive,
    component: Account,
  },
  {
    label: "آدرس ها",
    path: "/dashboard/Addresses",
    subPath: "/Addresses",
    icon: AddressIcon,
    activeIcon: AddressIconsActive,
    component: Addresses,
  },
  {
    label: "سفارشات اخیر",
    path: "/dashboard/orders",
    subPath: "/orders",
    icon: order,
    activeIcon: orderActive,
    component: Orders,
  },
  {
    label: "سوابق خرید",
    path: "/dashboard/purchases",
    subPath: "/purchases",
    icon: SecureIcon,
    activeIcon: SecureIconActive,
    component: Purchases,
  },
  {
    label: "تخفیف‌ها",
    path: "/dashboard/discounts",
    subPath: "/discounts",
    icon: discount,
    activeIcon: discountActive,
    component: Discounts,
  },
  {
    label: " گردش حساب",
    path: "/dashboard/financial-history",
    subPath: "/financial-history",
    icon: TransactionsIcon,
    activeIcon: TransactionsIconActive,
    component: Financial,
  },
  {
    label: "پیشنهادات",
    path: "/dashboard/demands",
    subPath: "/demands",
    icon: suggestion,
    activeIcon: suggestionActive,
    component: Demands,
  },
  {
    label: "پرسش و پاسخ",
    path: "/dashboard/comments",
    subPath: "/comments",
    icon: tiket,
    activeIcon: tiketActive,
    component: Comments,
  },
  {
    label: "تنظیمات",
    path: "/dashboard/settings",
    subPath: "/settings",
    icon: SettingsIcon,
    activeIcon: SettingsIconActive,
    component: Settings,
  },
  {
    label: "خروج",
    path: "/sign-out",
    subPath: "/",
    icon: SignOutIcon,
    activeIcon: SignOutIcon,
  },
];

export default routes;
