import Carousel from "react-material-ui-carousel";
import styled from "styled-components";
import PersianArtImage from "../../Assets/homePage/persian-art.jpg";
import PersianArtistImage from "../../Assets/homePage/persian-artist.webp";
import LifeArtImage from "../../Assets/homePage/life-art.jpg";
import ShopImage from "../../Assets/homePage/shop.jpg";
import { Button } from "@mui/material";

export default function HomeSlider() {
  return (
    <SliderSec>
      <Carousel
        autoPlay={true}
        stopAutoPlayOnHover={true}
        animation="slide"
        indicators={true}
        navButtonsAlwaysVisible={true}
        duration={1000}
      >
        <LifeArt>
          <ImageBox>
            <img src={LifeArtImage} alt="هنر زندگی" />
          </ImageBox>
          <h2>با هنر زندگی آشنا شوید.</h2>
          <Button variant="contained">بیاموزید</Button>
        </LifeArt>
        <Shop>
          <ImageBox>
            <img src={ShopImage} alt="فروشگاه" />
          </ImageBox>
          <Button variant="contained">فروشگاه</Button>
          <h2>از فروشگاه سنت شاپ بازدید کنید</h2>
        </Shop>
        <PersianArt>
          <ImageBox>
            <img src={PersianArtImage} alt="هنرهای ایرانی" />
          </ImageBox>
          <h2>هنرهای ایرانی را بشناسید</h2>
          <Button variant="contained">هنر ایرانی</Button>
        </PersianArt>
        <PersianArtist>
          <ImageBox>
            <img src={PersianArtistImage} alt="هنرمند ایرانی" />
          </ImageBox>
          <h2>با هنرمندان ایران زمین آشنا شوید</h2>
          <Button variant="contained">هنرمند ایرانی</Button>
        </PersianArtist>
      </Carousel>
    </SliderSec>
  );
}

const SliderSec = styled.div`
  width: 100vw;
  height: calc(100vh - 65px);

  & > div {
    height: 100%;
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vw;

  & > h2 {
    font-size: 36px;
    margin: 0 0 10px;
    color: #fff;
    z-index: 100;
    width: fit-content;
  }

  & > button {
    width: fit-content;
    font-size: 24px;
  }
`;
const LifeArt = styled(Slide)`
  align-items: flex-end;
`;

const Shop = styled(Slide)`
  justify-content: flex-end;

  & > h2 {
    margin: 10px 0 0;
  }
`;

const PersianArt = styled(Slide)`
  align-items: flex-end;
  justify-content: flex-end;
`;

const PersianArtist = styled(Slide)`
  align-items: flex-end;
  justify-content: flex-start;
`;

const ImageBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
