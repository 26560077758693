import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Typography,
  Dialog,
  Box,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import { useUser } from "../context/userContext";
import { BaseBackURL } from "../Constant/apis";
import axios from "axios";
import CloseButton from "../Components/closeButton";
import { useNavigate } from "react-router-dom";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function Pay() {
  const { state, dispatch } = useUser();
  const [price, setPrice] = useState(state.currentPrice);
  const [lastPrice, setLastPrice] = useState(state.currentPrice);
  const [discount, setDiscount] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [isShoping, setIsShoping] = useState(state.hasCart);
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // if (confirm) {
    dispatch({ type: "SET_PAYMENT", payload: confirm });
    // }
    if (state.payment) {
      // navigate('/pay-result')
    }
  }, [confirm]);

  const handleChange = (e) => {
    setDiscount(e.target.value);
  };

  const getLastPtice = () => {
    const amount = price - (price * discount) / 100;
    setLastPrice(amount);
  };

  useEffect(() => {
    dispatch({ type: "SET_CURRENTPRICE", payload: lastPrice });
  }, [lastPrice]);

  const checkCredit = () => {
    if (state.balance >= lastPrice) {
      setSuccessModal(true);
    } else {
      setFailedModal(true);
      setIsShoping(true);
    }
  };

  const getCredit = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}/user/details`,
      headers: { Authorization: "Bearer " + state.token },
    };

    axios(config)
      .then((res) => {
        dispatch({ type: "SET_BALANCE", payload: res.data.credit });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCredit();
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_HAS_CART", payload: isShoping });
  }, [isShoping]);

  const confirmPay = () => {
    addTransaction();
    setConfirm(true);
    navigate("/pay-result");
  };

  console.log("date",new Date().toJSON())

  const addTransaction = () => {
    const data = new FormData();
    data.append("price", -state.currentPrice);
    data.append("time", new Date().toJSON().slice(11, 16));
    data.append("date", new Date().toJSON().slice(0, 10));
    data.append("status", "N");

    let config = {
      method: "post",
      url: `${BaseBackURL}transactions/`,
      headers: { Authorization: "Bearer " + state.token },
      data: data,
    };

    axios(config)
    .then((res) => {
      dispatch({
        type: "SET_TRANSACTIONS",
        payload: res.data,
      });
      toast.success("   خرید شما با موفقیت ثبت شد   ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      addCredit(state.currentPrice);
    })
    .catch((err) => {
      console.log(err);
      toast.error("ارتباط با سرور برقرا نشد!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  };

  const addCredit = (price) => {
    const data = new FormData();
    data.append("credit", parseInt(state.balance) - parseInt(price));

    let config = {
      method: "put",
      url: `${BaseBackURL}/user/details`,
      headers: { Authorization: "Bearer " + state.token },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log("there", res);
        dispatch({
          type: "SET_BALANCE",
          payload: res.data.credit,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const updateOrder = () => {
    setConfirm(false);
    dispatch({ type: "SET_PAYMENT", payload: false });
    dispatch({ type: "SET_CONFIRM_ORDER", payload: false });
    navigate(-1);
  };

  return (
    <PageSec>
      <Back onClick={updateOrder}>
        <ReplyAllIcon sx={{ margin: "auto" }} />
      </Back>
      <Card sx={{ width: "80%", margin: "auto", padding: "5%" }}>
        <CardHeader
          sx={{ padding: "0px", marginBottom: "10px" }}
          title="مرحله پرداخت"
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>مبلغ فاکتور : </Typography>
              <Typography>
                <CustomCurrency
                  value={price}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography> هزینه ارسال:</Typography>
              <Typography>
                <CustomCurrency
                  value={0}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography> کد تخفیف :</Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <CustomSelect>
                  <InputLabel id="demo-simple-select-label">تخفیف</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    variant="filled"
                    value={discount}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>۱۰ درصد تخفیف </MenuItem>
                    <MenuItem value={20}>۲۰ درصد تخفبف</MenuItem>
                    <MenuItem value={30}>۳۰ درصد تخفیف</MenuItem>
                  </Select>
                </CustomSelect>
                <Button size="small" variant="contained" onClick={getLastPtice}>
                  اعمال تخفیف
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography> مبلغ نهایی :</Typography>
              <Typography>
                <CustomCurrency
                  value={lastPrice}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={checkCredit}>
              پرداخت
            </Button>
          </Grid>
        </Grid>
      </Card>

      {/* successModal */}
      <Dialog
        open={successModal}
        onClose={() => {
          setSuccessModal(false);
        }}
      >
        <Card sx={{ padding: "5% 10%" }}>
          <CloseButton
            onClose={() => {
              setSuccessModal(false);
            }}
          />
          <CardHeader title="پرداخت    " />
          <CardContent>
            <Typography sx={{ marginBottom: "15px" }}>
              در صورت تایید مبلغ فاکتور از حساب شما کسر میگردد
            </Typography>
          </CardContent>
          <Button
            variant="contained"
            sx={{ display: "flex", margin: "auto" }}
            onClick={confirmPay}
          >
            تایید
          </Button>
        </Card>
      </Dialog>

      {/* failedModal */}
      <Dialog
        open={failedModal}
        onClose={() => {
          setFailedModal(false);
        }}
      >
        <Card sx={{ padding: "5% 10%" }}>
          <CloseButton
            onClose={() => {
              setFailedModal(false);
            }}
          />
          <CardHeader title="متاسفانه موجودی کیف پول شما کم است" />
          <CardContent>
            <Typography sx={{ marginBottom: "15px" }}>
              لطفا برای شارژ کیف پول خود به گردش حساب داشبود خود بروید.
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              variant="contained"
              sx={{ display: "flex", margin: "auto" }}
              onClick={() => {
                navigate("/dashboard/financial-history");
              }}
            >
              گردش حساب
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ display: "flex", margin: "auto" }}
              onClick={() => {
                setFailedModal(false);
              }}
            >
              انصراف
            </Button>
          </Box>
        </Card>
      </Dialog>
    </PageSec>
  );
}

const PageSec = styled.section`
  width: 100%;
  height: 100vh;
  padding: 10%;
  background-color: #c7ddcc;
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;

const CustomSelect = styled(FormControl)`
  width: 65%;
  label {
    right: 48px;
    transform-origin: top right;
    text-align: right;
  }
`;

const Back = styled.div`
  position: absolute;
  display: flex;
  left: 1%;
  top: 10%;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #ffff;
  border: 1px solid black;
  cursor: pointer;
`;
