import React from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function CloseButton({onClose}){
    return(
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 16,
            left: 16,
            
          }}
        >
          <CloseIcon />
        </IconButton>
    )
}