import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function SelectDate() {
  const [time, setTime] = useState("");
  
  const handleSendSelection = (event) => {
    setTime(event.target.value)
    
  }
 
  return (
    <FormGroup>
      <FormControlLabel  control={<Checkbox  value="ساعت ۹ الی ۱۱"    onClick={(event) => handleSendSelection(event)}   />} label="ساعت ۹ الی ۱۱" />
      <FormControlLabel  control={<Checkbox  value="ساعت ۱۲ الی ۱۴"   onClick={(event) => handleSendSelection(event)}   />} label="ساعت ۱۲ الی ۱۴" />
      <FormControlLabel  control={<Checkbox  value=" ساعت ۱۵ الی ۱۶ " onClick={(event) => handleSendSelection(event)}    /> } label=" ساعت ۱۵ الی ۱۶  " />
    </FormGroup>
  );
}
