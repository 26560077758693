import React from "react";
import styled from "styled-components";
import CurrencyFormat from "react-currency-format";


export default function TotalPrice (price){
    return(
        <CustomCurrency
        value={price.price.price * price.price.amount}
        thousandSeparator={true}
        suffix={"ریال"}
        disabled
      />
    )
}

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;