import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Card,
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  TextField,
  CardHeader,
  Dialog,
  Box,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate, useParams } from "react-router-dom";
import data from "../data/shop.json";
import GoBack from "../Components/GoBack";
import CloseButton from "../Components/closeButton";
import { Add } from "@mui/icons-material";
import DemandBtn from "../Components/Shop/DemandButton";
import { useUser } from "../context/userContext";
import CurrencyFormat from "react-currency-format";

export default function Product() {
  const { product } = useParams();
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [demadModal, setDemandModal] = useState(false);
  const { state, dispatch } = useUser();
  const [cartItem, setCartItem] = useState(state.cart);
  const [duplicate, setDuplicate] = useState(false);
  const [addCart, setAddCart] = useState(false);

  useEffect(() => {
    if (product === "مراقبت و زیبایی") {
      setProductList([data.beauty][0]);
    } else if (product === "مواد غذایی") {
      setProductList([data.Foods][0]);
    } else if (product === "پوشاک") {
      setProductList([data.clothing][0]);
    } else if (product === "گیاهان دارویی") {
      setProductList([data.MedicinalPlants][0]);
    }
  }, []);

  const handelCart = (order) => {
    let orderOrigin = order;
    let amount = { amount: 1 };

    if (cartItem.find((x) => x.id === order.id)) {
      setDuplicate(true);
    } else {
      orderOrigin = { ...orderOrigin, ...amount };
      setCartItem([...cartItem, orderOrigin]);
      setAddCart(true);
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_CART", payload: cartItem });
  }, [cartItem]);

  const productCard = productList.map((item) => (
    <Grid item xs={6} md={3}>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={() => navigate(item.title)}>
          <CardMedia
            component="img"
            height="140"
            image={item.img}
            alt="green iguana"
            sx={{ objectFit: "contain" }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {item.title}
            </Typography>
            <TextExpand variant="body2" color="text.secondary">
              {item.expand}
            </TextExpand>
          </CardContent>
        </CardActionArea>
        <CardLabel>
          <Button
            size="small"
            color="primary"
            variant="contained"
            href="javascript:;"
            endIcon={<AddShoppingCartIcon sx={{ mr: 1 }} />}
            onClick={() => {
              handelCart(item);
            }}
          >
            افزودن به سبد خرید
          </Button>
          <Price>
            <CustomCurrency
              value={item.price}
              thousandSeparator={true}
              suffix={"ریال"}
              disabled
            />
          </Price>
        </CardLabel>
      </Card>
    </Grid>
  ));

  return (
    <PageSec>
      <GoBack />
      <TitlePart>
        <h1>فروشگاه محصولات سنت‌شاپ</h1>
        <h2>فهرست کالاها</h2>

        <DemandBtn />
      </TitlePart>
      <Grid container spacing={2} sx={{ mt: 2, width: "80%" }}>
        {productList.length === 0 ? (
          <Grid item xs={12}>
            <Card>
              {" "}
              <TextExpand variant="body2" color="text.secondary">
                موردی یافت نشد
              </TextExpand>
            </Card>{" "}
          </Grid>
        ) : (
          productCard
        )}
      </Grid>
      <Dialog
        open={demadModal}
        onClose={() => {
          setDemandModal(false);
        }}
      >
        <DemandModal>
          <Card dir="rtl" sx={{ width: "100%" }}>
            <CardHeader title="ثبت پیشنهاد" />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <CustomTextField
                    label="موضوع مورد نظر"
                    variant="standard"
                    dir="rtl"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CustomTextField
                    label="شرح موضوع"
                    variant="standard"
                    dir="rtl"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setDemandModal(false);
                    }}
                  >
                    ثبت پیشنهاد
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DemandModal>
      </Dialog>
      <Dialog
        open={duplicate}
        onClose={() => {
          setDuplicate(false);
        }}
      >
        <Card sx={{ padding: "10px" }}>
          <CloseButton
            onClose={() => {
              setDuplicate(false);
            }}
          />
          <CardContent sx={{ width: "100%" }}>
            <Typography sx={{ marginBottom: "15px" }}>کاربر گرامی</Typography>
            <Typography sx={{ marginBottom: "15px" }}>
              کالای مورد نظر قبلا درسبد خرید شما قرار گرفته .لطفا برای تغییر
              تعداد سفارش به سبد خرید خود مراجعه فرمایید.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "100px" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                سبد خرید
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setDuplicate(false);
                }}
              >
                خروج
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
      <Dialog
        open={addCart}
        onClose={() => {
          setAddCart(false);
        }}
      >
        <Card sx={{ padding: "10px" }}>
          <CloseButton
            onClose={() => {
              setAddCart(false);
            }}
          />
          <CardContent sx={{ width: "100%" }}>
            <Typography sx={{ marginBottom: "15px" }}>کاربر گرامی</Typography>
            <Typography sx={{ marginBottom: "15px" }}>
              کالای مورد نظر درسبد خرید شما قرار گرفت.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "100px" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                سبد خرید
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setAddCart(false);
                }}
              >
                ادامه خرید
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitlePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 60px;
  position: relative;
  width: 80%;

  & > h1 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
  }

  & > h2 {
    font-size: 24px;
    text-align: center;
  }
`;

const DemandButton = styled(Button)`
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

const CardLabel = styled(CardActions)`
  justify-content: space-between;
`;

const Price = styled(Typography)`
  span {
    font-size: 0.8rem;
    font-weight: 300;
  }
`;

const TextExpand = styled(Typography)`
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const DemandModal = styled.div`
  padding: 20px;
`;

const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;

const AddIcon = styled(Add)`
  margin-left: 7px !important;
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;
