import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GoBack from "../Components/GoBack";
import {
  Card,
  Grid,
  Typography,
  Button,
  CardContent,
  ImageList,
  ImageListItem,
  CardActionArea,
  CardMedia,
  CardActions,
  Dialog,
  Box,
  Tab,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate, useParams } from "react-router-dom";
import data from "../data/shop.json";
import Carousel from "react-material-ui-carousel";
import productData from "../data/shop.json";
import { useUser } from "../context/userContext";
import CloseButton from "../Components/closeButton";
import CurrencyFormat from "react-currency-format";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function ProductDetails() {
  const { product } = useParams();
  const { title } = useParams();
  const [category, setCategory] = useState([]);
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const { state, dispatch } = useUser();
  const [cartItem, setCartItem] = useState(state.cart);
  const [duplicate, setDuplicate] = useState(false);
  const [addCart, setAddCart] = useState(false);
  const [value, setValue] = useState("1");

  useEffect(() => {
    if (title === "مراقبت و زیبایی") {
      setCategory(data.beauty);
    } else if (title === "مواد غذایی") {
      setCategory(data.Foods);
    } else if (title === "پوشاک") {
      setCategory(data.clothing);
    } else if (title === "گیاهان دارویی") {
      setCategory(data.MedicinalPlants);
    }
  }, []);

  const productName = category.filter((x) => x.title === product);

  const handelCart = (order) => {
    let orderOrigin = order;
    let amount = { amount: count };
    if (cartItem.find((x) => x.id === order.id)) {
      setDuplicate(true);
    } else {
      orderOrigin = { ...orderOrigin, ...amount };
      setCartItem([...cartItem, orderOrigin]);
      setAddCart(true);
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_CART", payload: cartItem });
  }, [cartItem]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const details = productName.map((item) => (
    <Grid container spacing={2} sx={{ mt: 2, width: "80%" }}>
      <Grid item xs={6}>
        <Carousel
          autoPlay={true}
          stopAutoPlayOnHover={true}
          animation="slide"
          indicators={true}
          navButtonsAlwaysVisible={true}
          duration={1000}
        >
          {item.gallery.map((pic) => (
            <ImageListItem key={pic.img}>
              <img
                src={pic.img}
                srcSet={pic.img}
                alt={pic.alt}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </Carousel>
      </Grid>
      <Grid item xs={6}>
        <Details>
          <Card sx={{ p: 2, direction: "rtl", textAlign: "start" }}>
            <h1>{item.title}</h1>
            <h2>{item.subtitle}</h2>

            {item.number && (
              <div>
                <h3>نحوه عرضه:</h3>
                <p>{item.number}</p>
              </div>
            )}
            {item.color && (
              <div>
                <h3>رنگ:</h3>
                <p>{item.color}</p>
              </div>
            )}
            {item.size && (
              <div>
                <h3>نحوه عرضه:</h3>
                <p>{item.size}</p>
              </div>
            )}
            {item.example && (
              <div>
                <h3>وزن:</h3>
                <p>{item.weight}</p>
              </div>
            )}
            <div>
              <h3>نحوه ارسال:</h3>
              <p>{item.deliveryType}</p>
            </div>

            <div>
              <h3>موجودی:</h3>
              <p>{item.available ? "موجود" : "ناموجود"}</p>
            </div>
            <Amount>
              <h3>تعداد خرید:</h3>
              <p>
                <span
                  onClick={() => {
                    setCount(count - 1);
                  }}
                >
                  -
                </span>
                <b>{count}</b>
                <span
                  onClick={() => {
                    setCount(count + 1);
                  }}
                >
                  +
                </span>
              </p>
              <FinalPrice>
                <CustomCurrency
                  value={(item.price - item.discount) * count}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                  className={item.discount ? "LineThrough" : ""}
                />
              </FinalPrice>
            </Amount>
            <ShoppingBox>
              <Price>
                <h3>قیمت:</h3>
                <PriceBox>
                  <CustomCurrency
                    value={item.price}
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                    className={item.discount ? "LineThrough" : ""}
                  />
                  {item.discount && (
                    <CustomCurrency
                      className="PriceWithDiscount"
                      value={item.price - item.discount}
                      thousandSeparator={true}
                      suffix={"ریال"}
                      disabled
                    />
                  )}
                </PriceBox>
              </Price>
              <Button
                size="small"
                color="primary"
                variant="contained"
                sx={{ mt: 2 }}
                endIcon={<AddShoppingCartIcon sx={{ mr: 1 }} />}
                onClick={() => {
                  handelCart(item);
                }}
              >
                افزودن به سبد خرید
              </Button>
            </ShoppingBox>
          </Card>
        </Details>
      </Grid>

      <Grid item xs={12}>
        <Card title="توضیحات" sx={{ mt: 4 }}>
          <CardContent>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="توضیحات" value="1" />
                    <Tab label="ارزش کیفی" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Description>
                    <p>{item.combinations}</p>
                    <p>{item.content}</p>
                  </Description>
                </TabPanel>
                <TabPanel value="2">
                  <Description>
                    <p>ارزش کیفی محصول</p>
                  </Description>
                </TabPanel>
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="نظرات" sx={{ mt: 4 }}>
          <CardContent>
            <Description>
              <h3 style={{ marginBottom: "30px" }}>نظرات کاربران</h3>
              <div>
                {item.comments.map((comment) => (
                  <Comment>
                    <h4>{comment.name}</h4>
                    <p>{comment.comment}</p>
                  </Comment>
                ))}
              </div>
            </Description>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="توضیحات" sx={{ mt: 4 }}>
          <CardContent>
            <h3 style={{ marginBottom: "30px" }}>محصولات مشابه </h3>
            <SimilarProducts>
              {productData.beauty.map((item) => (
                <Grid item xs={6} md={3}>
                  <Card sx={{ maxWidth: 345, height: 300 }}>
                    <CardActionArea onClick={() => navigate(item.title)}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={item.img}
                        alt="green iguana"
                        sx={{ objectFit: "contain" }}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <TextExpand variant="body2" color="text.secondary">
                          {item.expand}
                        </TextExpand>
                      </CardContent>
                    </CardActionArea>
                    <CardLabel>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        href="javascript:;"
                        endIcon={<AddShoppingCartIcon sx={{ mr: 1 }} />}
                      >
                        افزودن به سبد خرید
                      </Button>
                      <Price>
                        {" "}
                        {item.price} <span>تومان</span>
                      </Price>
                    </CardLabel>
                  </Card>
                </Grid>
              ))}
            </SimilarProducts>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="توضیحات" sx={{ mt: 4 }}>
          <CardContent>
            <h3 style={{ marginBottom: "30px" }}>محصولات پیشنهادی این استان</h3>
            <SimilarProducts>
              {productData.beauty.map((item) => (
                <Grid item xs={6} md={3}>
                  <Card sx={{ maxWidth: 345, height: 300 }}>
                    <CardActionArea onClick={() => navigate(item.title)}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={item.img}
                        alt="green iguana"
                        sx={{ objectFit: "contain" }}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <TextExpand variant="body2" color="text.secondary">
                          {item.expand}
                        </TextExpand>
                      </CardContent>
                    </CardActionArea>
                    <CardLabel>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        href="javascript:;"
                        endIcon={<AddShoppingCartIcon sx={{ mr: 1 }} />}
                      >
                        افزودن به سبد خرید
                      </Button>
                      <Price>
                        {" "}
                        {item.price} <span>تومان</span>
                      </Price>
                    </CardLabel>
                  </Card>
                </Grid>
              ))}
            </SimilarProducts>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ));

  console.log("set:", productName.expand);
  return (
    <PageSec>
      <GoBack />
      {details}
      <Dialog
        open={duplicate}
        onClose={() => {
          setDuplicate(false);
        }}
      >
        <Card sx={{ padding: "10px" }}>
          <CloseButton
            onClose={() => {
              setDuplicate(false);
            }}
          />
          <CardContent sx={{ width: "100%" }}>
            <Typography sx={{ marginBottom: "15px" }}>کاربر گرامی</Typography>
            <Typography sx={{ marginBottom: "15px" }}>
              کالای مورد نظر قبلا درسبد خرید شما قرار گرفته .لطفا برای تغییر
              تعداد سفارش به سبد خرید خود مراجعه فرمایید.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "100px" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                سبد خرید
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setDuplicate(false);
                }}
              >
                خروج
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
      <Dialog
        open={addCart}
        onClose={() => {
          setAddCart(false);
        }}
      >
        <Card sx={{ padding: "10px" }}>
          <CloseButton
            onClose={() => {
              setAddCart(false);
            }}
          />
          <CardContent sx={{ width: "100%" }}>
            <Typography sx={{ marginBottom: "15px" }}>کاربر گرامی</Typography>
            <Typography sx={{ marginBottom: "15px" }}>
              کالای مورد نظر درسبد خرید شما قرار گرفت.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "100px" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                سبد خرید
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setAddCart(false);
                }}
              >
                ادامه خرید
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 24px;
    margin-left: 20px;
  }

  p {
    font-size: 36px;
  }

  input {
    font-size: 24px;

    &.PriceWithDiscount {
      font-size: 18px;
    }

    &.LineThrough {
      text-decoration: line-through;
    }
  }

  span {
    font-size: 0.8rem;
    font-weight: 300;
  }
`;

const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 22px;
    margin-bottom 10px;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    margin: 0 0 20px;
  }

  select{
    margin-bottom:30px;
  }

`;

const Amount = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 30px;

    b {
      margin: 0 20px;
    }

    span {
      font-size: 28px;
      font-weight: 700;
      cursor: pointer;
    }
  }
`;

const FinalPrice = styled.div`
  margin-right: auto;

  input {
    font-size: 20px;
  }
`;

const ShoppingBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    margin-top: 0;
  }
`;

const Description = styled.div`
  text-align: right;
`;

const TextExpand = styled(Typography)`
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const CardLabel = styled(CardActions)`
  justify-content: space-between;
`;

const SimilarProducts = styled.div`
  display: flex;
  justify-content: start;
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;
