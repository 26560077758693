import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function CategoryCard({ title, image, path }) {
  const naviagte = useNavigate();
  return (
    <Card
      onClick={() => {
        naviagte(path);
      }}
    >
      <h2>{title}</h2>
      <img src={image} alt={title} />
    </Card>
  );
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ededed;
  border-radius: 10px;
  width: 20vw;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
  padding: 20px;
  margin-bottom: 50px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.2s ease-out;
  margin: 0 20px 50px 0;

  &:hover {
    transform: scale(1.1);
  }

  h2 {
    font-size: 18px;
    line-height: 20px;
    text-align-center;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 15vh;
    object-fit: contain;
  }
`;
