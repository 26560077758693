import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useUser } from "../../context/userContext";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BaseBackURL } from "../../Constant/apis";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";

export default function Dashboard() {
  const { state, dispatch } = useUser();
  const navigate = useNavigate();
  const [gift, setGift] = useState("0000");
  const [cash, setCash] = useState("0000");

  useEffect(() => {
    if (state.token !== null) {
      handleData();
      GetOrders();
      GetDiscount();
      GetPurchases();
      getCredit();
    }
  }, []);

  const handleData = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}customer/detail`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        console.log("res:", res);
        dispatch({ type: "SET_FNAME", payload: res.data.firstname });
        dispatch({ type: "SET_LNAME", payload: res.data.lastname });
        dispatch({ type: "SET_AGE", payload: res.data.age });
        dispatch({ type: "SET_MOBILEN", payload: res.data.mobilenumber });
        dispatch({ type: "SET_PHONEN", payload: res.data.phonenumber });
        dispatch({ type: "SET_SEX", payload: res.data.sex });
        dispatch({ type: "SET_MAIL", payload: res.data.email });
        dispatch({ type: "SET_PERSONALCODE", payload: res.data.personalcode });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCredit = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}/user/details`,
      headers: { Authorization: "Bearer " + state.token },
    };

    axios(config)
      .then((res) => {
        dispatch({ type: "SET_BALANCE", payload: res.data.credit });
      })
      .catch((err) => {
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const GetOrders = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}orders`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        console.log("res2:", res);
        //  dispatch({ type: "SET_USERNAME", payload: res.data.username });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetDiscount = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}discounts`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        console.log("res3:", res);
        //  dispatch({ type: "SET_USERNAME", payload: res.data.username });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetPurchases = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}purchases`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        console.log("res4:", res);
        //  dispatch({ type: "SET_USERNAME", payload: res.data.username });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageSec>
      <TitlePart>
        <h1>
          {state.firstName} {state.lastName}
        </h1>
        <h4>
          موجودی کیف پول:
          <CustomCurrency
            value={state.balance !== null ? state.balance : 0}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        </h4>
        <h4>
          موجودی نقدی:{" "}
          <CustomCurrency
            value={cash}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        </h4>
        <h4>
          اعتبار هدیه:
          <CustomCurrency
            value={gift}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        </h4>
        <h3>
          مجموع اعتبار:
          <CustomCurrency
            value={parseInt(gift) + parseInt(cash)}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        </h3>
      </TitlePart>
      <CardContainer>
        <Card sx={{ minWidth: 275, background: "#01850b" }}>
          <CardActionArea
            onClick={() => {
              navigate("/dashboard/purchases");
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 24 }}
                color="text.secondary"
                gutterBottom
              >
                خریدها
              </Typography>
              <Typography
                sx={{ fontSize: 16 }}
                variant="h5"
                component="div"
              ></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {state.purchases.length} خرید موفق
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, margin: "0 30px", background: "#fffb84" }}>
          <CardActionArea
            onClick={() => {
              navigate("/dashboard/orders");
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 24 }}
                color="text.secondary"
                gutterBottom
              >
                سفارش‌ها
              </Typography>
              <Typography
                sx={{ fontSize: 16 }}
                variant="h5"
                component="div"
              ></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {state.orders.length} سفارش جاری
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, background: "#ff8a8a" }}>
          <CardActionArea
            onClick={() => {
              navigate("/dashboard/discounts");
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 24 }}
                color="text.secondary"
                gutterBottom
              >
                کد تخفیف
              </Typography>
              <Typography
                sx={{ fontSize: 16 }}
                variant="h5"
                component="div"
              ></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                ۸۰ هزار تومان تخفیف
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </CardContainer>
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 80vh;
`;

const TitlePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;
  gap: 20px;

  h4,
  h3 {
    display: flex;
    justify-content: space-between;
    width: 30vw;

    input {
      text-align: left;
    }
  }
`;

const CardContainer = styled.div`
  display: flex;

  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  font-size: 1.78vw;
  font-weight: 700;
  color: black;
  @media (max-width: 480px) {
    font-size: 3vw;
  }
`;
