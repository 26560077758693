import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  TextField,
  Dialog,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CurrencyFormat from "react-currency-format";

import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useUser } from "../../context/userContext";
import { BaseBackURL } from "../../Constant/apis";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import { transactionSchema } from "../../Components/schema";
import AdapterJalali from "@date-io/date-fns-jalali";
import CloseButton from "../../Components/closeButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: ltr;
  }
`;

const AddIcon = styled(Add)`
  margin-left: 7px !important;
`;

const AmountRoot = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`;

const Financial = () => {
  const { state, dispatch } = useUser();
  const [rows, setRows] = useState(state.transactions);
  const [addModal, setAddModal] = useState(false);
  const [credit, setCredit] = useState(state.balance);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [modalStep, setModalStep] = useState(1);
  const [routeCart, setRouteCart] = useState(false);
  const [date, setDate] = useState(new Date(2022, 3, 7));
  const [gift, setGift] = useState("0000");
  const [cash, setCash] = useState("0000");
  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 70,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
      flex: 1,
    },
    { field: "date", headerName: " تاریخ تراکنش", width: 130, flex: 1 },
    {
      field: "price",
      headerName: "مبلغ تراکنش",
      width: 130,
      renderCell: (row) => {
        return (
          <CustomCurrency
            value={row.value}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        );
      },
      flex: 1,
    },
    {
      field: "time",
      headerName: "ساعت ",
      width: 200,
      flex: 1,
    },
    {
      field: "code",
      headerName: "شماره پیگیری",
      width: 200,
      flex: 1,
    },
  ];

  useEffect(() => {
    getTransactions();
    getCredit();
    console.log("dari");
  }, [credit]);

  const getTransactions = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}transactions`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_TRANSACTIONS", payload: res.data });
        setRows(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const totalTransactions =()=>{
  //  let x = [];
  //  [state.transactions].map(h=>{return(x.push(h.price))});
  //  const total =x.reduce((a, b) => a + b, 0)
  //  return total;
  // }

  const getCredit = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}/user/details`,
      headers: { Authorization: "Bearer " + state.token },
    };

    setLoading(true);
    axios(config)
      .then((res) => {
        console.log("credit", res);
        dispatch({ type: "SET_BALANCE", payload: res.data.credit });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  const addCredit = (price) => {
    const data = new FormData();
    data.append(
      "credit",
      state.balance
        ? parseInt(price) + parseInt(state.balance)
        : parseInt(price)
    );

    let config = {
      method: "put",
      url: `${BaseBackURL}/user/details`,
      headers: { Authorization: "Bearer " + state.token },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log("there", res);
        dispatch({
          type: "SET_BALANCE",
          payload: res.data.credit,
        });
        setRouteCart(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //open modal for add credit (route from cart component)
  useEffect(() => {
    if (state.hasCart) {
      setAddModal(true);
      dispatch({ type: "SET_HAS_CART", payload: false });
    }
  }, []);

  const initialValues = {
    price: "",
    date: "",
    time: "",
    code: "",
  };

  const SchemaValues = Yup.object().shape({
    price: Yup.string().required("مبلغ تراکنش الزامی است."),
    date: Yup.string().required("تاریخ تراکنش الزامی است."),
    time: Yup.string().required("زمان تراکنش الزامی است."),
    code: Yup.string().required("کد پیگیری تراکنش الزامی است."),
  });

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    console.log([year, month, day].join("-"));

    return [year, month, day].join("-");
  }

  return (
    <PageSec>
      <Grid container spacing={4} width="100%">
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="موجودی حساب شما" />
            <CardContent>
              <AmountRoot>
                <Button
                  variant="contained"
                  onClick={() => setAddModal(true)}
                  startIcon={<AddIcon />}
                >
                  افزایش موجودی
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  sx={{ width: "50%" }}
                >
                  <CustomCurrency
                    thousandSeparator={true}
                    suffix={"ریال"}
                    disabled
                    value={state.balance !== null ? state.balance : 0}
                  />
                </LoadingButton>
              </AmountRoot>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            {/* <CardHeader title="مجموع خرید های انجام شده از سایت" /> */}
            <CardContent>
              <ShowCredit>
                <h4>
                  موجودی نقدی:{" "}
                  <b>
                    {" "}
                    <CustomCurrency
                      value={cash}
                      thousandSeparator={true}
                      suffix={"ریال"}
                      disabled
                    />{" "}
                  </b>
                </h4>
                <h4>
                  اعتبار هدیه:{" "}
                  <b>
                    {" "}
                    <CustomCurrency
                      value={gift}
                      thousandSeparator={true}
                      suffix={"ریال"}
                      disabled
                    />{" "}
                  </b>
                </h4>
                <h3>
                  مجموع اعتبار:{" "}
                  <b>
                    {" "}
                    <CustomCurrency
                      value={parseInt(gift) + parseInt(cash)}
                      thousandSeparator={true}
                      suffix={"ریال"}
                      disabled
                    />{" "}
                  </b>
                </h3>
              </ShowCredit>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="سوابق مالی" />
            <CardContent sx={{ height: "50vh" }}>
              {load ? (
                <CircularProgress />
              ) : (
                <TableRoot
                  rows={rows}
                  columns={columns}
                  ppostCodeSize={5}
                  rowsPerPpostCodeOptions={[5]}
                  checkboxSelection
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
          setModalStep(1);
        }}
      >
        <TransactionModal>
          <Card dir="rtl" sx={{ width: "100%" }}>
            <CloseButton
              onClose={() => {
                setAddModal(false);
              }}
            />
            <CardHeader title="افزودن موجودی" />
            <CardContent>
              {modalStep === 1 ? (
                <ModalTitle>
                  <h2>لطفا مبلغ مورد نظر را به شماره کارت زیر واریز فرمایید</h2>
                  <h3>
                    ۶۲۱۹-۸۶۱۰-۵۲۳۱-۵۷۲۷ <span>ولی‌الله درزی</span>
                  </h3>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setModalStep(2);
                    }}
                  >
                    لطفا پس از واریز ادامه دهید.
                  </Button>
                </ModalTitle>
              ) : (
                <Formik
                  validationSchema={SchemaValues}
                  initialValues={initialValues}
                  initialErrors={initialValues}
                  onSubmit={(values, { resetForm }) => {
                    const data = new FormData();
                    console.log(values.price);
                    data.append("price", values.price);
                    data.append("time", values.time);
                    data.append("date", values.date);
                    data.append("status", "N");
                    // data.append("code",code);
                    setModalStep(1);

                    let config = {
                      method: "post",
                      url: `${BaseBackURL}transactions/`,
                      headers: { Authorization: "Bearer " + state.token },
                      data: data,
                    };
                    axios(config)
                      .then((res) => {
                        // dispatch({ type: "SET_BALANCE", payload: parseFloat(res.data.price) + parseFloat(state.balance )  });
                        dispatch({
                          type: "SET_TRANSACTIONS",
                          payload: res.data,
                        });
                        toast.success("شارژ کیف پول با موفقیت انجام شد", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setCredit(res.data.price);
                        addCredit(values.price);
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error("ارتباط با سرور برقرا نشد!", {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      });

                    setAddModal(false);
                    resetForm();
                  }}
                >
                  {({
                    setFieldValue,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <Grid container spacing={4}>
                        <FormTitle>اطلاعات واریزی را وارد فرمایید</FormTitle>
                        <Grid item xs={12} sm={12}>
                          <CurrencyFormat
                            customInput={CustomTextField}
                            id="price"
                            value={values.price}
                            label="مبلغ مورد نظر"
                            variant="standard"
                            thousandSeparator={true}
                            suffix={"ریال"}
                            dir="rtl"
                            fullWidth
                            required
                            onChange={(e) => {
                              const numberWithRials = e.target.value.replace(
                                /,/g,
                                ""
                              );
                              const number = numberWithRials.replace(
                                /ریال/g,
                                ""
                              );
                              const numberInt =
                                number === "" ? 0 : parseInt(number);
                              setFieldValue("price", numberInt);
                            }}
                            onBlur={handleBlur}
                          />

                          {errors.price && touched.price && (
                            <ErrorText>{errors.price}</ErrorText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {/* <CustomTextField
              id="date"
              label="تاریخ"
              variant="standard"
              dir="rtl"
              InputLabelProps={{
                shrink: true,
              }}
              format={"YYYY-MM-DD"}
              type="date"
              fullWidth
              required
              onChange={handleChange}
              onBlur={handleBlur}
            /> */}
                          <LocalizationProvider dateAdapter={AdapterJalali}>
                            <DatePicker
                              styl={{ width: "100%" }}
                              mask="____/__/__"
                              value={values.date}
                              onChange={(newValue) => {
                                console.log(newValue);

                                return setFieldValue(
                                  "date",
                                  formatDate(newValue)
                                );
                              }}
                              renderInput={(params) => (
                                <CustomTextField
                                  style={{ width: "100%" }}
                                  variant="standard"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {errors.date && touched.date && (
                            <ErrorText>{errors.date}</ErrorText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <CustomTextField
                            id="time"
                            label="ساعت"
                            variant="standard"
                            value={values.time}
                            type="time"
                            dir="ltr"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.time && touched.time && (
                            <ErrorText>{errors.time}</ErrorText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <CustomTextField
                            id="code"
                            label="شماره پیگیری"
                            variant="standard"
                            value={values.code}
                            dir="rtl"
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.code && touched.code && (
                            <ErrorText>{errors.code}</ErrorText>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            ثبت
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </CardContent>
          </Card>
        </TransactionModal>
      </Dialog>
      {/* route back to cart */}
      <Dialog
        open={routeCart}
        onClose={() => {
          setRouteCart(false);
        }}
      >
        <Card sx={{ padding: "5% 10%" }}>
          <CloseButton
            onClose={() => {
              setRouteCart(false);
            }}
          />

          <CardContent>
            <Typography sx={{ marginBottom: "15px" }}>
              آیا میخواهید به سبد خرید خود بازگردید ؟
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              sx={{ display: "flex", margin: "auto" }}
              onClick={() => {
                navigate("/cart");
              }}
            >
              بله
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ display: "flex", margin: "auto" }}
              onClick={() => {
                setRouteCart(false);
              }}
            >
              خیر
            </Button>
          </Box>
        </Card>
      </Dialog>
    </PageSec>
  );
};

export default Financial;

const PageSec = styled.section`
  position: relative;
`;

const TransactionModal = styled.div`
  padding: 20px;
`;

const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-bottom: 20px;

  & > h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  & > h3 {
    margin-bottom: 30px;
    span {
      margin-right: 20px;
    }
  }
`;

const FormTitle = styled.h4`
  font-size: 16px;
  margin-top: 30px;
`;

const ErrorText = styled.p`
  color: #fc8181;
  font-size: 0.75rem;
  width: 100%;
  text-align: right;
  margin: 0;
  margin-right: 2%;
  margin-top: 2%;
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
`;

const ShowCredit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h4,
  h3 {
    display: flex;
    justify-content: space-between;
  }
`;
