import Footer from "./Footer";
import Header from "./Header";
import styled from "styled-components";

export default function LayOut({ children }) {
  return (
    <section>
      <Header />
      <BodyPart>{children}</BodyPart>
      <Footer />
    </section>
  );
}

const BodyPart = styled.div`
  min-height: 100vh;
`;
