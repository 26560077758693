import React from "react";
import styled from "styled-components";
import { useUser } from "../../context/userContext";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Discounts() {
  const { state, dispatch } = useUser();
  const navigate = useNavigate();
  return (
    <PageSec>
      <TitlePart>
        <h2>تخفیف‌های کاربر</h2>
        <h1>
          {state.firstName} {state.lastName}
        </h1>
        
      </TitlePart>
      <CardContainer>
        <Card sx={{ minWidth: 275, background: "#e9e9e9", margin: "0 15px" }}>
          <CardActionArea
            onClick={() => {
              navigate("/dashboard/purchases");
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 24 }}
                color="text.secondary"
                gutterBottom
              >
                کد تخفیف
              </Typography>
              <Typography sx={{ fontSize: 16 }} variant="h5" component="div">
                ۴۰۰۰۰ تومان
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                ha6hj40
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                تاریخ انقضا تخفیف : ۱۴۰۱/۱۲/۰۲
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ minWidth: 275, margin: "0 15px", background: "#e9e9e9" }}>
          <CardActionArea
            onClick={() => {
              navigate("/dashboard/orders");
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 24 }}
                color="text.secondary"
                gutterBottom
              >
                کد تخفیف
              </Typography>
              <Typography sx={{ fontSize: 16 }} variant="h5" component="div">
                ۴۰۰۰۰ تومان
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                ha6hj40
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                تاریخ انقضا تخفیف : ۱۴۰۱/۱۲/۰۲
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </CardContainer>
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitlePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width:480px){
    flex-direction:column;
    gap:10px;
  }
`;
