import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useUser } from "../context/userContext";
import { ButtonGroup, Button ,Box,Dialog,Card,CardContent,Typography} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseButton from "./closeButton";

export default function SelectAmount(amount) {
  const { state, dispatch } = useUser();
  const [itemCount, setItemCount] = useState(amount.amount.amount);
  const [addModal, setAddModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const mainOrder = state.cart.find((x) => x.id === amount.amount.id);

  useEffect(() => {
    mainOrder.amount = itemCount;
    dispatch({ type: "SET_CART", payload: [...state.cart] });
  }, [itemCount]);

  useEffect(() => {
    if (itemCount === 0) {
      setAddModal(true);
    }
    if (confirmDelete) {
      const id = state.cart.indexOf(mainOrder);
      const newCart = [...state.cart];
      newCart.splice(id, 1);
      dispatch({ type: "SET_CART", payload: newCart });
    }
  }, [itemCount,confirmDelete]);

  return (
    <>
      <ButtonGroup>
        <ChangeAmount
          onClick={() => {
            setItemCount(itemCount + 1);
          }}
        >
          {" "}
          <AddCircleOutlineIcon fontSize="small" />
        </ChangeAmount>
        <Button sx={{ borderRightColor: "inherit !important" }}>
          {itemCount}
        </Button>
        <ChangeAmount
          onClick={() => {
            setItemCount(Math.max(itemCount - 1, 0));
          }}
        >
          {" "}
          <RemoveCircleOutlineIcon fontSize="small" />
        </ChangeAmount>
      </ButtonGroup>

      {/* Dialog for confirm delete */}
      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <Card sx={{ padding: "20px" }}>
          <CloseButton
            onClose={() => {
              setAddModal(false);
            }}
          />
          <CardContent>
            <Typography sx={{ marginBottom: "15px" }}>کاربرگرامی </Typography>
            <Typography sx={{ marginBottom: "10px" }}>
              آیا از حذف کالا از سبد خرید اطمینان دارید ؟
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button variant="contained" onClick={()=>{
                setConfirmDelete(true)
              }}>
                بلی
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                خیر
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}

const ChangeAmount = styled(Button)`
  border: none !important;
`;
