import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./Pages/Home";
import ShopPage from "./Pages/Shop";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Product from "./Pages/product";
import ProductDetails from "./Pages/ProductDetails";
import DashboardHome from "./Pages/dashboard";
import LayOut from "./Components/Layout/LayOut";
import { UserState } from "./context/userContext";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Cart from "./Pages/Cart";
import InitialInvoice from "./Pages/InitialInvoice";
import Pay from "./Pages/Pay";
import PayResult from "./Pages/PayResult";
import { Routes, Route } from "react-router-loading";
import rtlPlugin from "stylis-plugin-rtl";
import { StyleSheetManager } from "styled-components";
import ForgetPass from "./Pages/forgetPass";

function App() {
  const THEME = createTheme({
    direction: "rtl",
    overflow: "hidden",
    typography: {
      fontFamily: `YekanBakh`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });
  return (
    <div className="App">
      <StyleSheetManager stylisPlugins={[rtlPlugin]}>
        <UserState>
          <ThemeProvider theme={THEME}>
            <BrowserRouter>
              <LayOut>
                <Routes>
                  {/* <Route path="/" element={<Home />} /> */}
                  <Route path="/dashboard/*" element={<DashboardHome />} />
                  <Route path="/" element={<ShopPage />} />
                  <Route path="/login" element={<Login />} loading />
                  <Route path="/signup" element={<SignUp />} loading />
                  <Route
                    path="/forget-password"
                    element={<ForgetPass />}
                    loading
                  />
                  <Route path="/category/:product" element={<Product />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/initial-invoice" element={<InitialInvoice />} />
                  <Route path="/pay" element={<Pay />} />
                  <Route path="/pay-result" element={<PayResult />} />
                  <Route
                    path="/category/:title/:product"
                    element={<ProductDetails />}
                  />
                </Routes>
              </LayOut>
            </BrowserRouter>
          </ThemeProvider>
        </UserState>
      </StyleSheetManager>
      <ToastContainer />
    </div>
  );
}

export default App;
