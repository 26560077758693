import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AboutUs from "../Components/HomePage/AboutUs";
import CreditPart from "../Components/HomePage/CreditPart";
import CustomerSatisfaction from "../Components/HomePage/CustomerSatisfaction";
import Geography from "../Components/HomePage/Geography";
import HandicraftStore from "../Components/HomePage/HandicraftStore";
import HealthyLife from "../Components/HomePage/HealthyLife";
import HomeSlider from "../Components/HomePage/HomeSlider";
import LifeArt from "../Components/HomePage/LifeArt";
import PersianArt from "../Components/HomePage/PersianArt";
import PersianArtist from "../Components/HomePage/PersianArtist";

export default function Home() {
  return (
    <PageSec>
      <HomeSlider />
      <AboutUs />
      <HealthyLife />
      <CreditPart />
      <LifeArt />
      <PersianArt />
      <PersianArtist />
      <HandicraftStore />
      <CustomerSatisfaction />
      <Geography />
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
