import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { Link } from "@mui/material";
import { BaseBackURL } from "../Constant/apis";
import axios from "axios";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { basicSchema } from "../Components/schema";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useLoadingContext } from "react-router-loading";
import { Formik } from "formik";
import * as Yup from "yup";

export default function ForgetPass() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useUser();
  const loadingContext = useLoadingContext();

  useEffect(() => {
    loadingContext.done();
  }, []);

  const initialValues = {
    phone: "",
  };

  const nameRegex = /^[0-9\u06F0-\u06F90-9]+$/;

  const SchemaValues = Yup.object().shape({
    phone: Yup.string()
      .required("شماره تلفن الزامی است")
      .min(11, "شماره تلفن باید ۱۱ رقمی باشد.")
      .max(11, "شماره تلفن باید ۱۱ رقمی باشد.")
      .matches(nameRegex, "شماره تلفن را درست وارد کنید"),
  });

  return (
    <PageSec dir="rtl">
      <Formik
        validationSchema={SchemaValues}
        initialValues={initialValues}
        initialErrors={initialValues}
        onSubmit={(values, { resetForm }) => {
          // resetForm();
        }}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <LoginForm onSubmit={handleSubmit} autoComplete="off">
              <h2>فراموشی گذرواژه</h2>
              <FormBox>
                <label>شماره تلفن همراه</label>
                <CustomInput
                  name="phone"
                  label="نام کاربری "
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="نام کاربری خود را وارد کنید."
                  className={errors.phone && touched.phone ? "input-error" : ""}
                />
                {errors.phone && touched.phone && (
                  <ErrorText>{errors.phone}</ErrorText>
                )}
              </FormBox>

              <LoadingButton
                loading={loading}
                variant="contained"
                disabled={isSubmitting}
                type="submit"
              >
                تأیید
              </LoadingButton>
              <p onClick={() => navigate("/login")}>وارد شوید</p>
              <p onClick={() => navigate("/signup")}>ثبت نام کنید</p>
            </LoginForm>
          </>
        )}
      </Formik>
    </PageSec>
  );
}

const PageSec = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:#c7ddcc;
`;

const LoginForm = styled.form`
  width: 33vw;
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 20px;
  & > h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    // text-align: right;
  }

  & > p {
    margin-top: 30px;
    color: #1976d2;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-decoration-color: rgba(25, 118, 210, 0.4);
    cursor: pointer;
    font-size: 16px;

    &:last-child {
      margin-top: 0;
    }
  }
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 10px;
  & > label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #5e5e5e;
    text-align: right;
    width: 200px;
  }

  & > input {
    height: 45px;
    border: 1px solid #5e5e5e;
    border-radius: 4px;
    padding: 0 10px;
    width: 200px;

    &:focus {
      border: 1px solid #5e5e5e;
      outline: 0;
    }
  }
`;

const CustomInput = styled.input`
  &.input-error {
    border-color: red !important;
  }
`;
const ErrorText = styled.span`
  color: #fc8181;
  font-size: 0.75rem;
  width: 200px;
  text-align: right;
  position: absolute;
  bottom: 0;
`;
