import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,

} from "@mui/material";
import styled from "styled-components";
import { useUser } from "../../context/userContext";
import { BaseBackURL } from "../../Constant/apis";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import CloseButton from "../../Components/closeButton";
import Invoice from "../../Components/ invoice";

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: rtl;
  }
`;

const Orders = () => {
  const { state, dispatch } = useUser();
  const [rows, setRows] = useState(state.orders);
  const [load, setLoad] = useState(true);
  const [addModal,setAddModal]=useState(false)

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}orders`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_ORDERS", payload: res.data });
        setRows(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 70,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
    },
    { field: "date", headerName: " تاریخ", width: 70 },
    { field: "number", headerName: "شماره فاکتور", width: 100 },
    {
      field: "product",
      headerName: "نام کالا",
      type: "number",
      width: 140,
    },
    {
      field: "price",
      headerName: "ارزش کل فاکتور",
      type: "number",
      width: 140,
    },
    {
      field: "status",
      headerName: "وضعیت ",
      width: 140,
    },
    {
      field: "row",
      headerName: "مشاهده فاکتور",
      width: 150,
      renderCell: (row) => {
        return (

          <>
            <Button variant="contained" onClick={()=>{setAddModal(true)}}>مشاهده</Button>
            <Dialog
              open={addModal}
              onClose={() => {
                setAddModal(false);
              }}
            > 
              <Card sx={{padding:"20px",width:"40vw" }}>
                <CloseButton onClose={()=>{setAddModal(false)}}/>
                <CardContent>
                  <Invoice rows={[row.row]} />
                </CardContent>
              </Card>

            </Dialog>
          </>
        );
      },
      flex:1
    },
  ];

  return (
    <div style={{ height: "90vh", width: "80%", background: "#fff" }}>
      {load ? (
        <CircularProgress />
      ) : (
        <TableRoot
          rows={rows}
          columns={columns}
          ppostCodeSize={5}
          rowsPerPpostCodeOptions={[5]}
          checkboxSelection
        />
      )}
    </div>
  );
};

export default Orders;
