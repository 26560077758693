import "../../App.css";
import Navigation from "../../Components/dashboard/navigation/navigation";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components";
import { useUser } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Login from "../Login";

const PageRoot = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => (!props.matches ? `70px` : `0`)};
  background-color: #c7ddcc;
`;

export default function DashboardHome() {
  const { state, dispatch } = useUser();
  const navigate = useNavigate();
  const theme = createTheme();
  const themeRoot = useTheme();
  const matches = useMediaQuery(themeRoot.breakpoints.up("sm"));

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex" }}>
        <Navigation />
        <PageRoot matches={matches}>
          <Routes>
            {state.token === null || !state.token ? (
              <Route path="*" element={<Login />}></Route>
            ) : (
              routes.map((route, index) => {
                return (
                  <Route
                    exact
                    key={index}
                    path={route.subPath}
                    element={<route.component />}
                  ></Route>
                );
              })
            )}
          </Routes>
        </PageRoot>
      </div>
    </ThemeProvider>
  );
}
