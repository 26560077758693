import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useUser } from "../../context/userContext";
import { BaseBackURL } from "../../Constant/apis";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select from "@mui/material/Select";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useFormik } from "formik";
import { AddressSchema } from "../../Components/schema";
import CloseButton from "../../Components/closeButton";
import data from "../../data/state.json";

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: ltr;
  }
`;

export default function Addresses() {
  const { state, dispatch } = useUser();
  const [rows, setRows] = useState(state.address);
  const navigate = useNavigate();
  const [addModal, setAddModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);

  const onSubmit = async (values, actions) => {
    const data = new FormData();
    data.append("province", values.province);
    data.append("city", values.city);
    data.append("description", values.description);
    data.append("number", values.number);
    data.append("unit", values.unit);
    data.append("postcode", values.postcode);
    setLoading(true);
    let config = {
      method: "post",
      url: `${BaseBackURL}addresses/`,
      headers: { Authorization: "Bearer " + state.token },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log("change:", res);
        toast.success("اطلاعات شما با موفقیت ثبت شد !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        getAddress();
      })
      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
    setAddModal(false);
    setRows([...rows, { id: rows[rows.length - 1].id + 1, ...values }]);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      province: "",
      city: "",
      description: "",
      number: "",
      unit: "",
      postcode: "",
    },
    validationSchema: AddressSchema,
    onSubmit,
  });

  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 20,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
      flex: "20px",
    },
    {
      field: "province",
      headerName: "استان",
      width: window.innerWidth * 0.15,
      flex: 1,
    },
    {
      field: "city",
      headerName: "شهر",
      width: window.innerWidth * 0.15,
      flex: 1,
    },
    // {
    //   field: "owner",
    //   headerName: "کد پستی",
    //   type: "number",
    //   width: 90,
    // },
    {
      field: "description",
      headerName: "شرح آدرس",
      type: "text",
      width: window.innerWidth * 0.2,
      flex: "500px",
    },
    {
      field: "number",
      headerName: "پلاک",
      type: "text",
      width: window.innerWidth * 0.15,
      flex: 1,
    },
    {
      field: "unit",
      headerName: "واحد",
      type: "text",
      width: window.innerWidth * 0.15,
      flex: 1,
    },
    {
      field: "action",
      headerName: "ویرایش",
      width: window.innerWidth * 0.15,
      renderCell: () => {
        return (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            endIcon={<DeleteIcon sx={{ marginRight: 2 }} />}
            onClick={handleDelete}
          >
            حذف آدرس
          </LoadingButton>
        );
      },
      flex: "200px",
    },
  ];

  useEffect(() => {
    getAddress();
    console.log("darzi");
  }, []);

  const handleDelete = (id) => {
    setOpen(true);
    console.log(id);
  };

  const getAddress = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}addresses`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_ADDRESS", payload: res.data });
        setRows(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdd = () => {
    setAddModal(true);
  };

  const deleteAddress = () => {
    setLoading(true);
    let config = {
      method: "delete",
      url: `${BaseBackURL}addresses/${id}`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        toast.success("اطلاعات شما با موفقیت ثبت شد !", {
          position: toast.POSITION.TOP_RIGHT,
        });

        dispatch({ type: "SET_ADDRESS", payload: res.data });
        getAddress();
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });

    setOpen(false);
  };

  //generate select box for selecting state and city
  const SelectingState = data.state.map((item, i) => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">استان</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="province"
              label="استان"
              value={values.province}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <MenuItem key={i} value={item.name}>
                {item.name}
              </MenuItem>
            </Select>
            {errors.province && touched.province && (
              <ErrorText>{errors.province}</ErrorText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">شهر</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="city"
              name="city"
              value={values.city}
              label="شهر"
              onChange={handleChange}
            >
              {item.citys.map((g, index) => {
                return (
                  <MenuItem key={index} value={g.city}>
                    {g.city}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.city && touched.city && (
              <ErrorText>{errors.city}</ErrorText>
            )}
          </FormControl>
        </Grid>
      </>
    );
  });

  return (
    <PageSec>
      <Header>
        <Button
          variant="contained"
          onClick={handleAdd}
          startIcon={<AddIcon sx={{ marginLeft: "7px", marginTop: "-4px" }} />}
        >
          افزودن
        </Button>
      </Header>
      <div style={{ height: "50vh", width: "100%", background: "#fff" }}>
        {load ? (
          <CircularProgress />
        ) : (
          <TableRoot
            rows={rows}
            columns={columns}
            ppostCodeSize={5}
            rowsPerPpostCodeOptions={[5]}
            checkboxSelection
            onRowClick={(row) => setId(row.id)}
          />
        )}
      </div>
      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <AddresseModal>
          <Card dir="rtl" sx={{ width: "100%" }}>
            <CloseButton
              onClose={() => {
                setAddModal(false);
              }}
            />
            <CardHeader title="آدرس جدید" />
            <CardContent>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={4}>
                  {/* {SelectingState} */}
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      id="province"
                      label="استان"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      required
                      value={values.province}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.province && touched.province && (
                      <ErrorText>{errors.province}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      id="city"
                      label="شهر"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      required
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.city && touched.city && (
                      <ErrorText>{errors.city}</ErrorText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      id="postcode"
                      label="کدپستی"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      required
                      value={values.postcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.postcode && touched.postcode && (
                      <ErrorText>{errors.postcode}</ErrorText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      id="description"
                      label="آدرس"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description && (
                      <ErrorText>{errors.description}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      id="number"
                      label="پلاک"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      type="tel"
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.number && touched.number && (
                      <ErrorText>{errors.number}</ErrorText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      id="unit"
                      label="واحد"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      type="tel"
                      value={values.unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.unit && touched.unit && (
                      <ErrorText>{errors.unit}</ErrorText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      ثبت
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </AddresseModal>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Card dir="rtl" sx={{ width: "100%" }}>
          <CloseButton
            onClose={() => {
              setOpen(false);
            }}
          />
          <CardHeader title="پیام" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography>آیا آدرس انتخاب شده حذف شود؟</Typography>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  onClick={deleteAddress}
                >
                  بلی
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(false)}
                >
                  خیر
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
    </PageSec>
  );
}

const PageSec = styled.section`
  position: relative;
  width: 80%;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
`;

const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
`;

const AddresseModal = styled.div`
  padding: 20px;
`;

const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;

const ErrorText = styled.p`
  color: #fc8181;
  font-size: 0.75rem;
  width: 100%;
  text-align: right;
  margin: 0;
  margin-right: 2%;
  margin-top: 4%;
`;
