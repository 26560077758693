import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  appRoot: {
    display: "flex",
  },
  appBar: {
    background: "#6EC177 !important",
    height:"11%",
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    },
  },
  appBarSpacer: {
    [theme.breakpoints.down("xs")]: {
      ...theme.mixins.toolbar,
    },
  },
  navigationLogo: {
    width: "100%",
    height:"100%",
    objectFit:"container",
    cursor: "pointer",
  },
  navigationLogoContainer: {
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
    width:"120px",
    height:"120px",
    borderRadius:"120px",
    border:"2px solid gray",
    background:"gray",
    marginInline:"auto",
  },
  navigationLogoContainerMobile: {
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
    width:"35px",
    height:"35px",
    borderRadius:"35px",
    border:"2px solid gray",
    background:"gray",
    marginInline:"auto"
  },
  userName :{
    margin:"0",
    marginInline:"auto"
    
  },
  navigationDrawer: {
    width: 240,
    border: "none",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    position: "relative !important",
    height: "100vh !important",
    background: "#ededed !important",
  },
  navigationDrawerCollapse: {
    width: theme.spacing(9),
  },
  navigationToolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    ...theme.mixins.toolbar,
  },
  navigationToolbarCollapse: {
    justifyContent: "center",
    paddingRight: 0,
  },
  navigationList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  navigationSpacer: {
    flex: 1,
  },
  menuItemIcon: {
    width: "100%",
  },
  menuItem: {
    width: "80% !important",
    borderRadius: theme.spacing(1) + "!important",
    marginBottom: theme.spacing(1) + "!important",
  },
  menuItemText: {
    textAlign: "right",
  },
  menuItemActive: {
    backgroundColor: "#EBEBEC !important",
  },
}));
