import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  TextField,
  Dialog,
  CircularProgress,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useUser } from "../../context/userContext";
import { BaseBackURL } from "../../Constant/apis";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { commentSchema } from "../../Components/schema";
import CloseButton from "../../Components/closeButton";

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: ltr;
  }
`;

const AddIcon = styled(Add)`
  margin-left: 7px !important;
`;

const AmountRoot = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`;

const Financial = () => {
  const { state, dispatch } = useUser();
  const [rows, setRows] = useState(state.supports);
  const [addModal, setAddModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [firstModal, setFirstModal] = useState(false);

  useEffect(() => {
    getSupport();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 70,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
      flex: 1,
    },
    { field: "type", headerName: "موضوع تیکت", width: 200, flex: 1 },
    {
      field: "description",
      headerName: "شرح درخواست",
      width: 300,
      flex: 1,
    },
  ];

  const getSupport = () => {
    let config = {
      method: "get",
      url: `${BaseBackURL}supports`,
      headers: { Authorization: "Bearer " + state.token },
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_SUPPORT", payload: res.data });
        setRows(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (values, actions) => {
    const data = new FormData();
    data.append("type", values.type);
    data.append("description", values.description);
    data.append("status", "C");

    let config = {
      method: "post",
      url: `${BaseBackURL}supports/`,
      headers: { Authorization: "Bearer " + state.token },
      data: data,
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_SUPPORT", payload: res.data });
        toast.success("اطلاعات شما با موفقیت ثبت شد !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        getSupport();
      })
      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });

    setAddModal(false);
    actions.resetForm();
    setFirstModal(true);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      type: "",
      description: "",
    },
    validationSchema: commentSchema,
    onSubmit,
  });

  return (
    <PageSec>
      <Grid container spacing={4} width="100%">
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="ایجاد تیکت پشتیبانی" />
            <CardContent>
              <Button
                variant="contained"
                onClick={() => setAddModal(true)}
                startIcon={<AddIcon />}
              >
                ارتباط با پشتیبان
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="مجموع تیکت‌های پشتیبانی" />
            <CardContent>
              <Button variant="outlined" color="error" sx={{ width: "100%" }}>
                {state.supports.length} تیکت پشتیبانی
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="تیکت‌های پشتیبانی" />
            <CardContent sx={{ height: "50vh" }}>
              {load ? (
                <CircularProgress />
              ) : (
                <TableRoot
                  rows={rows}
                  columns={columns}
                  ppostCodeSize={5}
                  rowsPerPpostCodeOptions={[5]}
                  checkboxSelection
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <TransactionModal>
          <CloseButton
            onClose={() => {
              setAddModal(false);
            }}
          />
          <Card dir="rtl" sx={{ width: "100%" }}>
            <CardHeader title="پشتیبانی" />

            <CardContent>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <CustomTextField
                      id="type"
                      label="موضوع مورد نظر"
                      variant="standard"
                      dir="rtl"
                      fullWidth
                      required
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.type && touched.type && (
                      <ErrorText>{errors.type}</ErrorText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CustomTextField
                      id="description"
                      label="شرح موضوع"
                      variant="standard"
                      type="textarea"
                      multiline
                      rows={4}
                      dir="rtl"
                      fullWidth
                      required
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description && (
                      <ErrorText>{errors.description}</ErrorText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      ایجاد تیکت پشتیبانی
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </TransactionModal>
      </Dialog>
      <Dialog
        open={firstModal}
        onClose={() => {
          setFirstModal(false);
        }}
      >
        <ConfirmModal>
          <CloseButton
            onClose={() => {
              setFirstModal(false);
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: "30px" }}>
                با تشکر از پیام شما .در اسرع وقت جوابگوی شما هستیم.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ display: "flex", margin: "auto" }}
                onClick={() => {
                  setFirstModal(false);
                }}
              >
                تایید
              </Button>
            </Grid>
          </Grid>
        </ConfirmModal>
      </Dialog>
    </PageSec>
  );
};

export default Financial;

const PageSec = styled.section`
  position: relative;
`;

const TransactionModal = styled.div`
  padding: 20px;
`;

const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;

const ErrorText = styled.p`
  color: #fc8181;
  font-size: 0.75rem;
  width: 100%;
  text-align: right;
  margin: 0;
  margin-right: 2%;
  margin-top: 2%;
`;

const ConfirmModal = styled(Card)`
  padding: 20px;
`;
