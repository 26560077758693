import { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Dialog,
} from "@mui/material";
import { Add } from "@mui/icons-material";

export default function DemandBtn() {
  const [demandModal, setDemandModal] = useState(false);
  return (
    <>
      <DemandButton
        variant="contained"
        onClick={() => setDemandModal(true)}
        startIcon={<AddIcon />}
      >
        افزودن پیشنهاد
      </DemandButton>
      <Dialog
        open={demandModal}
        onClose={() => {
          setDemandModal(false);
        }}
      >
        <DemandModal>
          <Card dir="rtl" sx={{ width: "100%" }}>
            <CardHeader title="ثبت پیشنهاد" />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <CustomTextField
                    label="موضوع مورد نظر"
                    variant="standard"
                    dir="rtl"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CustomTextField
                    label="شرح موضوع"
                    variant="standard"
                    dir="rtl"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setDemandModal(false);
                    }}
                  >
                    ثبت پیشنهاد
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DemandModal>
      </Dialog>
    </>
  );
}

const DemandButton = styled(Button)`
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

const AddIcon = styled(Add)`
  margin-left: 7px !important;
`;

const DemandModal = styled.div`
  padding: 20px;
`;

const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;
