import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { Link } from "@mui/material";
import axios from "axios";
import { BaseBackURL } from "../Constant/apis";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLoadingContext } from "react-router-loading";
import { useEffect } from "react";
import hide from "../Assets/dashboard/hide.png";
import show from "../Assets/dashboard/show.png";

export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useUser();
  const loadingContext = useLoadingContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  const nameRegex = /^[a-zA-Z0-9]+$/;

  const SchemaValues = Yup.object().shape({
    firstName: Yup.string()
      .required("لطفا نام خود را وارد کنید")
      .min(3, "لطفا حداقل ۵کاراکتر وارد کنید"),
    lastName: Yup.string()
      .required("  لطفا نام خانوادگی خود را وارد کنید")
      .min(3, "لطفا حداقل ۵کاراکتر وارد کنید " ),
    username: Yup.string()
      .matches(nameRegex, "نام کاربری باید انگلیسی باشد.")
      .required("لطفا نام کاربری خود را وارد کنید")
      .min(5, "لطفا حداقل ۵ کاراکتر وارد کنید"),
    password: Yup.string()
      .matches(nameRegex, "پسورد باید شامل حروف و اعداد لاتین باشد.")
      .required("لطفا رمز عبور خود را واارد کنید")
      .min(8, "لطفا حداقل ۸ کاراکتر وارد کنید"),
    confirmPassword: Yup.string()
      .required("لطفا رمز عبور خود را وارد کنید.")
      .oneOf([Yup.ref("password"), null], "پسوردها تطابق ندارد."),
  });

  useEffect(() => {
    loadingContext.done();
  }, []);

  return (
    <PageSec>
      <h1>ثبت نام در سایت</h1>
      <Formik
        validationSchema={SchemaValues}
        initialValues={initialValues}
        initialErrors={initialValues}
        onSubmit={(values, { resetForm }) => {
          const data = new FormData();
          data.append("username", values.username);
          data.append("password", values.password);
          data.append("password2", values.confirmPassword);
          data.append("first_name", values.firstName);
          data.append("last_name", values.lastName);

          setLoading(true);

          let config = {
            method: "post",
            url: `${BaseBackURL}register`,
            data: data,
          };
          axios(config)
            .then((res) => {
              console.log(res);
              dispatch({
                type: "SET_DATA",
                payload: {
                  userName: values.username,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  balance: 0,
                  loggedIn: true,
                },
              });
              toast.success("اطلاعات شما با موفقیت ثبت شد !", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/login");
              setLoading(false);
              resetForm();
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.username) {
                toast.error("این نام کاربری قبلا استفاده شده است !", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else if (err.response.data.password) {
                toast.error("لطفا رمز عبور قوی‌تری انتخاب کنید !", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("اطلاعات وارد شده صحیح نمی باشد.!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
              setLoading(false);
            });
        }}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <SignUpForm onSubmit={handleSubmit} autoComplete="off">
            <h2>لطفا مشخصات زیر را وارد کنید:</h2>
            <FormBox>
              <label>نام</label>
              <CustomInput
                name="firstName"
                label="نام"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="نام خود را وارد کنید."
                className={
                  errors.firstName && touched.firstName ? "input-error" : ""
                }
              />
              {errors.firstName && touched.firstName && (
                <Error>{errors.firstName}</Error>
              )}
            </FormBox>
            <FormBox>
              <label>نام خانوادگی</label>
              <CustomInput
                name="lastName"
                label="نام خانوادگی"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="نام خانوادگی خود را وارد کنید."
                className={
                  errors.lastName && touched.lastName ? "input-error" : ""
                }
              />
              {errors.lastName && touched.lastName && (
                <Error>{errors.lastName}</Error>
              )}
            </FormBox>
            <FormBox>
              <label>نام کاربری</label>
              <CustomInput
                name="username"
                label="نام کاربری"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="نام کاربری خود را انتخاب کنید."
                className={
                  errors.username && touched.username ? "input-error" : ""
                }
              />
              {errors.username && touched.username && (
                <Error>{errors.username}</Error>
              )}
            </FormBox>

            <h2>رمز عبور انتخابی خود را وارد کنید:</h2>
            <FormBox>
              <label>رمز عبور</label>
              <CustomInput
                name="password"
                label="رمز عبور"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="رمز عبور خود را انتخاب کنید."
                className={
                  errors.password && touched.password ? "input-error" : ""
                }
              />
              <ShowPasswordIcon
                  onClick={handleClickShowPassword}
                  show={showPassword}
                ></ShowPasswordIcon>
              {errors.password && touched.password && (
                <Error>{errors.password}</Error>
              )}
            </FormBox>
            <FormBox>
              <label>تکرار رمز عبور</label>
              <CustomInput
                name="confirmPassword"
                label="تکرار رمز عبور"
                type={showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="رمز عبور انتخابی را مجدد وارد کنید."
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? "input-error"
                    : ""
                }
              />
              <ShowPasswordIcon
                  onClick={handleClickShowConfirmPassword}
                  show={showConfirmPassword}
                ></ShowPasswordIcon>
              {errors.confirmPassword && touched.confirmPassword && (
                <Error>{errors.confirmPassword}</Error>
              )}
            </FormBox>

            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={loading}
              type="submit"
            >
              تأیید
            </LoadingButton>
            <Link href="/login">وارد شوید</Link>
          </SignUpForm>
        )}
      </Formik>
    </PageSec>
  );
}

const PageSec = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px 100px;
  background-color:#c7ddcc;
`;

const SignUpForm = styled.form`
  width: 33vw;
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 20px;

  & > h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: right;
  }

  & > a {
    margin-top: 20px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    width: 70vw;
  }
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 10px;
  & > label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #5e5e5e;
    text-align: right;
    width: 200px;
  }

  & > input {
    height: 45px;
    border: 1px solid #5e5e5e;
    border-radius: 4px;
    padding: 0 10px;
    width: 200px;

    &:focus {
      border: 1px solid #5e5e5e;
      outline: 0;
    }
  }
`;

const CustomInput = styled.input`
  &.input-error {
    border-color: red !important;
  }
`;
const Error = styled.span`
  color: #fc8181;
  font-size: 0.75rem;
  width: 200px;
  text-align: right;
  position: absolute;
  bottom: 0;
`;


const ShowPasswordIcon = styled.div`
  background-image: url(${props=> props.show ? show : hide});
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
  position: absolute;
  left: 14px;
  bottom: 32px;
`;