import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { Link } from "@mui/material";
import { BaseBackURL } from "../Constant/apis";
import axios from "axios";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { basicSchema } from "../Components/schema";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useLoadingContext } from "react-router-loading";
import hide from "../Assets/dashboard/hide.png";
import show from "../Assets/dashboard/show.png";
import { Formik } from "formik";
import * as Yup from "yup";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useUser();
  const loadingContext = useLoadingContext();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    loadingContext.done();
  }, []);

  const initialValues = {
    username: "",
    password: "",
  };

  const nameRegex = /^[a-zA-Z0-9]+$/;

  const SchemaValues = Yup.object().shape({
    username: Yup.string()
      .matches(nameRegex, "نام کاربری باید شامل حروف و اعداد لاتین باشد.")
      .required("لطفا نام کاربری خود را وارد کنید")
      .min(5, "لطفا حداقل ۵کاراکتر وارد کنید"),
    password: Yup.string()
      .matches(nameRegex, "پسورد باید شامل حروف و اعداد لاتین باشد.")
      .required("  .لطفا رمز عبور را وارد کنید ")
      .min(5, "لطفا حداقل ۵کاراکتر وارد کنید"),
  });

  return (
    <PageSec dir="rtl">
      <Formik
        validationSchema={SchemaValues}
        initialValues={initialValues}
        initialErrors={initialValues}
        onSubmit={(values, { resetForm }) => {
          const data = new FormData();
          data.append("username", values.username);
          data.append("password", values.password);

          setLoading(true);

          let config = {
            method: "post",
            url: `${BaseBackURL}login`,
            data: data,
          };
          axios(config)
            .then((res) => {
              console.log("res:", res);
              dispatch({ type: "SET_TOKEN", payload: res.data.access });
              navigate("/");
              Cookies.set("token", res.data.access);
              setLoading(false);
            })
            .catch((err) => {
              console.log("error", err);
              if (err.request.status === 401) {
                toast.error("اطلاعات وارد شده درست نیست!!!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }

              setLoading(false);
            });
          resetForm();
        }}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <LoginForm onSubmit={handleSubmit} autoComplete="off">
              <h2>ورود به سایت</h2>
              <FormBox>
                <label>نام کاربری</label>
                <CustomInput
                  id="username"
                  label="نام کاربری "
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="نام کاربری خود را وارد کنید."
                  className={
                    errors.username && touched.username ? "input-error" : ""
                  }
                />
                {errors.username && touched.username && (
                  <ErrorText>{errors.username}</ErrorText>
                )}
              </FormBox>
              <FormBox>
                <label>پسورد</label>
                <CustomInput
                  dir="rtl"
                  id="password"
                  label="رمز عبور"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="رمز عبور خود را وارد کنید."
                  className={
                    errors.password && touched.password ? "input-error" : ""
                  }
                />
                <ShowPasswordIcon
                  onClick={handleClickShowPassword}
                  show={showPassword}
                ></ShowPasswordIcon>
                {errors.password && touched.password && (
                  <ErrorText>{errors.password}</ErrorText>
                )}
              </FormBox>
              <p
                className="ForgetPass"
                onClick={() => navigate("/forget-password")}
              >
                فراموشی رمز عبور
              </p>

              <LoadingButton
                loading={loading}
                variant="contained"
                disabled={isSubmitting}
                type="submit"
              >
                ورود
              </LoadingButton>
              <p onClick={() => navigate("/signup")}>ثبت نام کنید</p>
            </LoginForm>
          </>
        )}
      </Formik>
    </PageSec>
  );
}

const PageSec = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #c7ddcc;
`;

const LoginForm = styled.form`
  width: 33vw;
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 20px;
  & > h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    // text-align: right;
  }

  & > p {
    margin-top: 30px;
    color: #1976d2;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-decoration-color: rgba(25, 118, 210, 0.4);
    cursor: pointer;
    font-size: 18px;

    &.ForgetPass {
      margin-top: 0;
      font-size: 14px;
    }
  }
  @media (max-width: 480px) {
    width: 70vw;
  }
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 10px;
  & > label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #5e5e5e;
    text-align: right;
    width: 200px;
  }

  & > input {
    height: 45px;
    border: 1px solid #5e5e5e;
    border-radius: 4px;
    padding: 0 10px;
    width: 200px;

    &:focus {
      border: 1px solid #5e5e5e;
      outline: 0;
    }
  }
`;

const CustomInput = styled.input`
  position: relative;
  &.input-error {
    border-color: red !important;
  }
`;
const ErrorText = styled.span`
  color: #fc8181;
  font-size: 0.75rem;
  width: 200px;
  text-align: right;
  position: absolute;
  bottom: 0;
`;

const ShowPasswordIcon = styled.div`
  background-image: url(${(props) => (props.show ? show : hide)});
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 15px;
  position: absolute;
  left: 14px;
  bottom: 32px;
`;
