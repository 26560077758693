import React from "react";
import styled from "styled-components";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Container = styled.div`
  height: 90vh;
  width: 100%;
  padding-top:2%;
`;

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: ltr;
  }
`;

export default function Invoice({rows}) {
  console.log("hamidreza",rows)
  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 70,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
      
    },
    { field: "date", headerName: " تاریخ", width: 130},
    { field: "number", headerName: "شماره فاکتور", width: 130 },
    {
      field: "product",
      headerName: " کالا",
      width: 140,
      
    },
    {
      field: "price",
      headerName: "ارزش کل فاکتور",
      width: 200,
      
    },

    {
      field: "producter",
      headerName: "خریدار",
      width: 130,
      
    },
  ];

  return (
    <Container>
      <TableRoot rows={rows} columns={columns} />
    </Container>
  );
}
