import styled from "styled-components";
import backpic from "../../Assets/footer/img.jpg";
import Button from "@mui/material/Button";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <FooterSec>
      <LinkPart>
        <Contact>
          <h2>تماس با ما</h2>
          <p>آدرس: پردیس، پارک فناوری پردیس، فضای کار اشتراکی کوآپ</p>
          <p>تلفن: ۰۹۲۱۲۷۴۶۷۳۴</p>
          <p>ایمیل: info@sonatshop.com</p>

          <CustomBox>
            <Button
              onClick={() => navigate("/shop")}
              startIcon={<LocalMallIcon />}
            >
              <span>فروشگاه</span>
            </Button>
          </CustomBox>

          <Social>
            <h2>شبکه های مجازی</h2>
            <Box sx={{ display: "flex", gap: "5px", justifyContent: "center" }}>
              <SocialBox>
                <FacebookOutlinedIcon />
              </SocialBox>
              <SocialBox>
                <GoogleIcon />
              </SocialBox>
              <SocialBox>
                <InstagramIcon />
              </SocialBox>
            </Box>
          </Social>
        </Contact>
        <Links>
          <h2>هنرمندان ایرانی</h2>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
        </Links>
        <Links>
          <h2>هنرهای ایرانی</h2>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
        </Links>
        <Links>
          <h2>هنر زندگی</h2>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
          <a href="/">استاد محمدرضا لطفی</a>
        </Links>
      </LinkPart>
      <CopyrightPart>
        تمامی حق اثر این وب سایت محفوظ و متعلق به سنت شاپ می باشد.
      </CopyrightPart>
    </FooterSec>
  );
}

const FooterSec = styled.section`
  displaty: flex;
  flex-direction: column;
  background-image:url(${backpic});
  background-size:cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 9vh 5vw;
  padding-bottom:0;

`;

const LinkPart = styled.section`
  display: flex;
  justify-content: space-between;
`;

const CopyrightPart = styled.section`
  margin-top:7vh;
  padding:20px;
  color: rgba(255,255,255,0.5);
  font-size: 13px;
  font-weight: 300;
  line-height: 1.5em;
  text-align:start;
  border-top: 1px dotted;
  margin-right:-4%;
  margin-left:-4%;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  a {
    text-decoration: none;
    color: #ffffff;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
    font-size:24px;
    &:hover {
      transform: scale(1.1);
    }
    @media(max-width:480px){
      font-size:10px;
    }
  }
  h2 {
    margin-bottom: 10px;
    font-size:36px;
    @media(max-width:480px){
      font-size:16px;
    }
  }
`;

const Contact = styled(Links)`
  align-items: flex-start;
  width: 30%;
  p {
    margin: 0;
    padding-bottom: 10px;
  }
`;

const CustomBox = styled(Box)`
  font-size: 15px;
  font-weight: bold;
  background-color: #9d0303;
  border-radius: 20px 20px 20px 20px;
  padding: 2px 5px;
  margin-inline: 10px;
  width: 80%;
  margin-top: 20px;
  display: flex;
  &:hover {
    background-color: black;
  }
  button {
    color: white;
    margin: auto;
    display: flex;
    align-items: flex-start;
    span {
      margin: 0;
      margin-inline: 2px;
    }
  }
`;

const Social = styled.div`
  margin: 20px 15px 40px;
`;

const SocialBox = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 40px !important;
  background-color: #ffffff !important;
  min-width: 40px !important;
`;
