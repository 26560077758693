import {
  Button,
  Dialog,
  Card,
  Typography,
  CardContent,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useUser } from "../context/userContext";
import CloseButton from "./closeButton";

export default function DeleteRow(row) {
  const { state, dispatch } = useUser();
  const [addModal, setAddModal] = useState(false);
  const item = state.cart.find((x) => x.id === row.row.id);
  const id = state.cart.indexOf(item);

  const confirmDelete = () => {
    setAddModal(true);
  };

  const handleDelete = () => {
    const newCart = [...state.cart];
    newCart.splice(id, 1);
    dispatch({ type: "SET_CART", payload: newCart });
  };
  return (
    <>
      <Button
        variant="contained"
        color="error"
        endIcon={<DeleteIcon sx={{ marginRight: 2 }} />}
        onClick={confirmDelete}
      ></Button>

      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <Card sx={{ padding: "20px" }}>
          <CloseButton
            onClose={() => {
              setAddModal(false);
            }}
          />
          <CardContent>
            <Typography sx={{ marginBottom: "15px" }}>کاربرگرامی </Typography>
            <Typography sx={{ marginBottom: "10px" }}>
              آیا از حذف کالا از سبد خرید اطمینان دارید ؟
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button variant="contained" onClick={handleDelete}>
                بلی
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setAddModal(false);
                }}
              >
                خیر
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}
