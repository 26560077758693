import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useUser } from "../../context/userContext";
import { BaseBackURL } from "../../Constant/apis";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import {  toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';


const CustomTextField = styled(TextField)`
  label {
    left: auto;
    right: auto;
    transform-origin: top right;
    text-align: right;
  }
`;

const Account = () => {
  const [sex, setSex] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age,setAge]=useState("");
  const [phoneNumber,setPhoneNumber]=useState("");
  const [mobileNumber,setMobileNumber]=useState("");
  const[email,setEmail]=useState("");
  const [personalCode,setPersonalCode]=useState("");
  const [loading,setLoading]=useState(false);



  const { state, dispatch } = useUser();

  useEffect(() => {
    setFirstName(state.firstName);
    setLastName(state.lastName);
    setAge(state.age);
    setSex(state.sex);
    setPhoneNumber(state.phoneNubmer);
    setMobileNumber(state.mobileNumber);
    setEmail(state.email);
    setPersonalCode(state.personalCode);
  }, [state]);

  const handleChange = (e) => {
    setSex(e.target.value);
  };

  const postChange =()=>{
    const data= new FormData();
    data.append("firstname", firstName);
    data.append("lastname",lastName);
    data.append("phonenumber",phoneNumber);
    data.append("sex",sex);
    data.append("mobilenumber",mobileNumber);
    data.append("age",age);
    // data.append("email",email);
    // data.append("personalcode",personalCode);

    setLoading(true);

    let config = {
      method: "put",
      url: `${BaseBackURL}customer/detail`,
      headers:{"Authorization": "Bearer "+state.token},
      data:data
    };
    axios(config)
      .then((res) => {
        console.log("change:",res);
        toast.success('اطلاعات شما با موفقیت ثبت شد !', {
          position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
        //  dispatch({ type: "SET_USERNAME", payload: res.data.username });
      })
      .catch((err) => {
        console.log(err);
        toast.error('ارتباط با سرور برقرا نشد!', {
          position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      });
  }


  return (
    <Card dir="rtl" sx={{ width: "80%" }}>
      <CardHeader title="مشخصات فردی" />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="نام"
              variant="standard"
              dir="rtl"
              fullWidth
              required
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="نام خانوادگی"
              variant="standard"
              dir="rtl"
              fullWidth
              required
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="تلفن همراه"
              variant="standard"
              dir="rtl"
              fullWidth
              type="tel"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">جنسیت</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sex}
                label="جنسیت"
                onChange={(e)=>setSex(e.target.value)}
              >
                <MenuItem value={"M"}>مرد</MenuItem>
                <MenuItem value={"F"}>زن</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              label=" کدملی"
              variant="standard"
              dir="rtl"
              fullWidth
              type="tel"
              value={personalCode}
              onChange={(e) => {
                setPersonalCode(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label=" سن"
              variant="standard"
              dir="rtl"
              fullWidth
              type="tel"
              value={age}
              onChange={(e) => {
                setAge(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              label=" تلفن ثابت"
              variant="standard"
              dir="rtl"
              fullWidth
              type="tel"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="ایمیل"
              variant="standard"
              dir="rtl"
              fullWidth
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton loading={loading} fullWidth variant="contained" onClick={postChange}>
              ثبت
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Account;
