import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

export default function GoBack() {
  const navigate = useNavigate();

  return (
    <Back  onClick={() => navigate(-1)}>
        <ReplyAllIcon  sx={{margin:'auto'}}/>
    </Back>
  );
}

const Back = styled.div`
  position: absolute;
  display:flex;
  left: 1%;
  top: 10%;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #ffff;
  border: 1px solid black;
  cursor:pointer;
`;
