import React, { useState, useEffect } from "react";
import { Button, Card, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useUser } from "../context/userContext";
import CurrencyFormat from "react-currency-format";
import { BaseBackURL } from "../Constant/apis";
import axios from "axios";

export default function PayResult() {
  const { state, dispatch } = useUser();
  const [rows, setRows] = useState(state.cart);
 


  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 70,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
    },
    {
      field: "date",
      headerName: " تاریخ",
      valueGetter: () => new Date().toJSON().slice(0, 10),
      flex: 1,
    },
    {
      field: "number",
      headerName: "شماره فاکتور",
      width: 100,
      valueGetter: () => 12345,
    },
    {
      field: "title",
      headerName: "نام کالا",
      type: "number",
      width: 140,
    },
    {
      field: "price",
      headerName: "ارزش کالا",
      type: "number",
      width: 140,
    },
    {
      field: "status",
      headerName: "وضعیت ",
      width: 140,
    },
  ];

  const setOrders = () => {
    const data = new FormData();
    data.append("price", state.currentPrice);
    data.append("number", 12345);
    data.append("date", new Date().toJSON().slice(0, 10));
    data.append("status", "C");
    data.append("product", rows[0].title);

    let config = {
      method: "post",
      url: `${BaseBackURL}orders/`,
      headers: { Authorization: "Bearer " + state.token },
      data:data,
    };
    axios(config)
      .then((res) => {
        dispatch({ type: "SET_ORDERS", payload: res.data });
        // setRows(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  

  useEffect(() => {
    setOrders();
    dispatch({ type: "SET_CART", payload: [] });
    dispatch({ type: "SET_PAYMENT", payload: false });
    dispatch({ type: "SET_CONFIRM_ORDER", payload: false });
  }, []);
  return (
    <PageSec>
      <h1>خرید شما با موفقیت ثبت شد</h1>
      <Card sx={{ height: "90vh", width: "80%" }}>
        <TableRoot rows={rows} columns={columns} />
      </Card>
      <Card
        sx={{
          height: "20vh",
          width: "80%",
          padding: "10px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
          }}
        >
          <Typography>جمع فاکتور </Typography>
          <CustomCurrency
            value={state.currentPrice}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        </Box>
      </Card>
      <Button
        sx={{ marginLeft: "10px" }}
        variant="contained"
        onClick={() => {
          window.location.href = "https://sonatshop.com/";
        }}
      >
        صفحه اصلی سنت شاپ
      </Button>{" "}
    </PageSec>
  );
}

const PageSec = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;

  & > h1 {
    margin-bottom: 20px;
  }

  & > h2 {
    margin-bottom: 50px;
  }

  & > button {
    width: fit-content;
  }
`;

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: rtl;
  }
  .MuiDataGrid-footerContainer {
    display: none;
  }
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;
