import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ButtonGroup,
  Button,
  Typography,
  Dialog,
  Box,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useUser } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import SelectAmount from "../Components/selectAmount";
import TotalPrice from "../Components/totalPrice";
import DeleteRow from "../Components/deleteRow";
import CloseButton from "../Components/closeButton";
import axios from "axios";
import { BaseBackURL } from "../Constant/apis";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function Cart() {
  const { state, dispatch } = useUser();
  const [addModal, setAddModal] = useState(false);
  const [rows, setRows] = useState(state.cart);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(70000);
  const [sumPrice, setSumPrice] = useState(state.currentPrice);
  const [credit, setCredit] = useState(false);
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (state.token === null || !state.token) {
      setAddModal(true);
    }
  }, []);

  //check if confirm order route to next level
  useEffect(() => {
    if (confirm) {
      dispatch({ type: "SET_CONFIRM_ORDER", payload: confirm });
    }
    if (state.confirmOrder) {
      navigate("/pay");
    }
  }, [confirm]);

  useEffect(() => {
    if (state.cart.length === 0) {
      setRows([]);
    } else {
      setRows([...state.cart]);
    }
  }, [state.cart]);

  //columns dataTable for show orders details
  const columns = [
    {
      field: "id",
      headerName: "ردیف",
      width: 70,
      renderCell: (row) => {
        return rows.indexOf(row.row) + 1;
      },
    },
    {
      field: "img",
      headerName: " تصویر کالا",
      width: 100,
      renderCell: (row) => {
        return (
          <TableImage>
            <img src={row.formattedValue} />
          </TableImage>
        );
      },
    },
    { field: "title", headerName: "نام کالا", width: 150 },
    {
      field: "amount",
      headerName: " تعداد ",
      width: 150,
      renderCell: (row) => {
        return <SelectAmount amount={row.row} />;
      },
    },
    {
      field: "price",
      headerName: " قیمت واحد  ",
      renderCell: (row) => {
        return (
          <CustomCurrency
            value={row.value}
            thousandSeparator={true}
            suffix={"ریال"}
            disabled
          />
        );
      },
      flex: 1,
    },
    {
      field: "total price",
      headerName: " قیمت مجموع  ",
      renderCell: (row) => {
        return <TotalPrice price={row.row} />;
      },
      flex: 1,
    },
    {
      field: "delete",
      headerName: " حذف ",
      renderCell: (row) => {
        return <DeleteRow row={row.row} />;
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    totalPay();
    lastPrice();
  }, [totalPrice, state.cart]);

  const totalPay = () => {
    let sumBox = [];
    rows.map((item, i) => {
      let number = item.amount * item.price;
      sumBox.push(number);
    });

    let total = 0;
    for (let i in sumBox) {
      total += sumBox[i];
    }
    console.log("order", state.cart);
    setTotalPrice(total);
    return totalPrice;
  };

  const lastPrice = () => {
    const amount = totalPrice - discount;
    dispatch({ type: "SET_CURRENTPRICE", payload: amount });
    setSumPrice(amount);
    return sumPrice;
  };

  const completePhuercases = () => {
    setConfirm(true);
    navigate("/initial-invoice");
  };

  //send cart for back
  const sendCart = () => {
    const data = new FormData();
    data.append(
      "number",
      state.cart.map((x) => x.amount)
    );
    data.append("product", `${state.cart.map((x) => x.title)[0]}`);
    console.log("pro", state.cart.map((x) => x.title)[0].toString());
    let config = {
      method: "post",
      url: `${BaseBackURL}cart/`,
      headers: { Authorization: "Bearer " + state.token },
      data: data,
    };

    axios(config)
      .then((res) => {
        console.log("change:", res);
        toast.success("اطلاعات شما با موفقیت ثبت شد !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        //  dispatch({ type: "SET_USERNAME", payload: res.data.username });
      })
      .catch((err) => {
        console.log(err);
        toast.error("ارتباط با سرور برقرا نشد!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Grid container sx={{ backgroundColor: "#c7ddcc;", minHeight: "100vh" }}>
      <Grid item xs={9}>
        <Card sx={{ margin: "20px" }}>
          <CardHeader title="کالاهای انتخاب شده" />
          <CardContent sx={{ height: "50vh" }}>
            <TableRoot
              rows={rows}
              columns={columns}
              ppostCodeSize={5}
              rowsPerPpostCodeOptions={[5]}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card sx={{ margin: "20px" }}>
          <CardHeader title="جزئیات فاکتور" />
          <CardContent>
            <TotoalContainer>
              <Typography>تعداد اقلام:</Typography>
              <Typography>{state.cart.length}</Typography>
            </TotoalContainer>
            <TotoalContainer>
              <Typography>جمع کل قبل از تخفیف:</Typography>
              <Typography>
                {" "}
                <CustomCurrency
                  value={totalPrice}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />{" "}
              </Typography>
            </TotoalContainer>
            <TotoalContainer>
              <Typography>سود شما از این خرید:</Typography>
              <Typography>
                <CustomCurrency
                  value={discount}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />{" "}
              </Typography>
            </TotoalContainer>
            <TotoalContainer>
              <Typography>جمع کل بعد از تخفیف:</Typography>
              <Typography>
                <CustomCurrency
                  value={sumPrice}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />{" "}
              </Typography>
            </TotoalContainer>
            <TotoalContainer className="FinalPrice">
              <Typography>مبلغ قابل پرداخت:</Typography>
              <Typography>
                <CustomCurrency
                  value={sumPrice}
                  thousandSeparator={true}
                  suffix={"ریال"}
                  disabled
                />{" "}
              </Typography>
            </TotoalContainer>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                marginTop: "30px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/");
                }}
              >
                بازگشت به فروشگاه
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={completePhuercases}
                style={{ marginTop: "20px" }}
              >
                ادامه فرایند خرید
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <Card sx={{ padding: "20px" }}>
          <CloseButton
            onClose={() => {
              setAddModal(false);
            }}
          />
          <CardContent>
            <Typography sx={{ marginBottom: "15px" }}>کاربرگرامی </Typography>
            <Typography sx={{ marginBottom: "10px" }}>
              لطفا وارد حساب کاربری خود شوید
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/login");
                }}
              >
                ورود
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                ثبت نام
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </Grid>
  );
}

const TableRoot = styled(DataGrid)`
  .MuiTablePagination-root {
    direction: ltr;
  }
`;

const ChangeAmount = styled(Button)`
  border: none !important;
`;

const TotoalContainer = styled.div`
  display: felx;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin: 10px auto;

  &.FinalPrice {
    p {
      font-weight: 700;
    }
  }
`;

const CustomCurrency = styled(CurrencyFormat)`
  border: none;
  outline: none;
  width: fit-content;
  white-space: no-wrap;
  background-color: inherit;
  text-align: end;
`;

const TableImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
